let scrollPos = 0;
const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") === -1 &&
  navigator.userAgent.indexOf("FxiOS") === -1;

const debounce = function (fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
};

const toggleMobileMenu = (option) => {
  switch (option) {
    case "expand":
      document.getElementById("mobile-nav").style.width = "100%";
      document.body.classList.add("overflow-hidden");
      break;
    case "close":
      document.getElementById("mobile-nav").style.width = "0";
      document.body.classList.remove("overflow-hidden");
      break;
    default:
      break;
  }
};
const scrollingTopDetector = () => {
  // detects new state and compares it with the new one
  if (document.body.getBoundingClientRect().top > scrollPos) {
    document.getElementById("header-wrapper").classList.add("fixed-top");
    document
      .getElementById("header-wrapper")
      .classList.add("animated-header-expand");
    document
      .getElementById("header-wrapper")
      .classList.remove("animated-header-close");
    document
      .getElementById("header-wrapper")
      .addEventListener("transitionend", (e) => {
        document
          .querySelector("#header-wrapper .header")
          .classList.remove("d-none");
      });
    if (document.body.getBoundingClientRect().top === 0) {
      document.getElementById("header-wrapper").classList.remove("fixed-top");
    }
    if (document.querySelector(".sticky")) {
      document.querySelector(".sticky").style.top = "8px";
    }
  } else if (
    document.body.getBoundingClientRect().top < scrollPos &&
    !isSafari
  ) {
    document
      .getElementById("header-wrapper")
      .classList.add("animated-header-close");
    document
      .getElementById("header-wrapper")
      .classList.remove("animated-header-expand");
    document.querySelector("#header-wrapper .header").classList.add("d-none");
    if (document.querySelector(".sticky")) {
      document.querySelector(".sticky").style.top = "-120px";
    }
  } else if (document.body.getBoundingClientRect().top === 5 && isSafari) {
    document
      .getElementById("header-wrapper")
      .classList.add("animated-header-expand");
    document
      .getElementById("header-wrapper")
      .classList.remove("animated-header-close");
    document
      .getElementById("header-wrapper")
      .addEventListener("transitionend", (e) => {
        document
          .querySelector("#header-wrapper .header")
          .classList.remove("d-none");
      });
    document.getElementById("header-wrapper").classList.remove("fixed-top");
  }
  // saves the new position for iteration.
  if (window.location.hash.length === 0) {
    scrollPos--;
  }

  scrollPos = document.body.getBoundingClientRect().top;
};

export { debounce, toggleMobileMenu, scrollingTopDetector };
