import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from '../../Includes/Footer';
import Header from '../../Includes/Header';

const DataProcessing = () => {
      return (
            <>
                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>Data Processing Agreement | Communify</title>
                        <meta name="description" content='Reduce workplace friction to get business back to work' />
                  </Helmet>
                  <div className='terms-wrapper'>
                        <Header />
                        <div className='container'>
                              <div className="terms-heading cond-heading d-flex flex-column align-items-center">
                                    <h1 className='terms-title'>Data Processing Agreement</h1>
                                    <section className='introduction'>
                                          <p>
                                                This Data Processing Agreement (“DPA”) is by and between Teknikos, Inc. (“Company”), and Customer. This DPA shall be effective upon notice being provided (i) to a user designated as an administrator of Customer, (ii) immediately after completion of a user’s log-in, or (iii) by email to the registered email address provided for the administrator(s) for Customer’s account; and the Customer does not promptly object in writing after Company provided Customer with notice as described under (i)-(iii) above.
                                          </p>
                                          <p>
                                                This DPA is only valid and legally binding if the Customer is a data
                                                controller to which Article 3 of the GDPR applies. This DPA
                                          forms part of the Teknikos Terms and Conditions, available at <Link target='_blank' to='/terms-and-conditions'>COMMUNIFYAPP.COM/TERMS-AND-CONDITIONS</Link> the “Terms and Conditions”.
                                          </p>
                                          <p>
                                                Company is a party to this DPA for the sole purpose of complying with any obligations that are expressly stated to be Company obligations in this DPA, including assisting Customer to comply with its legal obligations under the GDPR with respect to the legalization of data transfers made by Company which occur when Customer uses the Application.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>1. DEFINITIONS</h2>
                                          <p>
                                                “Agreement” means, collectively, the Terms and Conditions, and any other agreement between Company and a specific subscriber or user, under which such person is able to access to one or more of the Applications.
                                          </p>
                                          <p>
                                                “Application” means one or more of the Company’s applications included in the “Communify” suite of software applications, a full listing of which is available at www.communifyapp.com.
                                          </p>
                                          <p>
                                                “Controller”, “data subject”, “personal data”, “personal data breach”, “process”, “processing”, “processor”, and “supervisory authority” have the same meanings as in the GDPR.
                                          </p>
                                          <p>
                                                “Customer” means a specific subscriber or user of one or more of the Applications, and any affiliates.
                                          </p>
                                          <p>
                                                “Data Exporter” means the Controller who transfers the Personal Data to a Data Importer.
                                          </p>
                                          <p>
                                                “Data Importer” means the Processor who agrees to receive from the Data Exporter Personal Data intended for Processing on his behalf after the transfer in accordance with his instructions and who is not subject to a third country’s system ensuring adequate protection within the meaning of Article 45 of the GDPR.
                                          </p>
                                          <p>
                                                “Data Protection Legislation” means all laws and regulations, including laws and regulations of the European Union, the European Economic Area (EEA), and their member states, applicable to the processing of Personal Data under the Agreement, as amended or replaced from time to time.
                                          </p>
                                          <p>
                                                “GDPR” means the General Data Protection Regulation (Regulation (EU) 2016/679) of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>2. DATA PROCESSING</h2>
                                          <p>
                                                2.1 Roles of the Parties. The parties acknowledge and agree that with regard to the processing of Personal Data, Customer is the controller and Company is the processor.
                                          </p>
                                          <p>
                                                2.2 Processing of Personal Data. Company may process Personal Data on behalf of Customer as part of supporting Customer’s access to and use of the Application. Company will process Personal Data as follows:
                                          </p>
                                          <div className='padding-left'>
                                                <p>
                                                      (a) Company will comply in all material respects with applicable Data Protection Legislation;
                                                </p>
                                                <p>
                                                      (b) Company will implement appropriate technical, physical and organizational measures to adequately safeguard and protect the security and confidentiality of Personal Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, including unauthorized access to or alteration of, Personal Data transmitted, stored or otherwise processed;
                                                </p>
                                                <p>
                                                      (c) Company will process the Personal Data only in accordance with any Customer instructions received by Company with respect to the processing of such Personal Data and in a manner necessary for Customer’s access to and use of the Application, which will, for the avoidance of doubt, include processing in accordance with this DPA and the Agreement;
                                                </p>
                                                <p>
                                                      (d) Company will ensure that persons authorized to process Personal Data on behalf of Company have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality;
                                                </p>
                                                <p>
                                                      (e) Company will assist Customer by appropriate technical and organization measures for the fulfillment of Customer’s obligations to respond to requests for exercising a data subject’s rights with respect to Personal Data under Chapter III of the GDPR;
                                                </p>
                                                <p>
                                                      (f) Company will assist Customer in complying with its obligations with respect to Personal Data pursuant to Articles 32 to 36 of the GDPR;
                                                </p>
                                                <p>
                                                      (g) Company will, at the written direction of Customer, (i) delete, destroy or return all Personal Data to Customer after the end of Customer’s subsription relating to processing, and (ii) delete existing copies unless the applicable law of the EU or an EU member state requires storage of the Personal Data;
                                                </p>
                                                <p>
                                                      (h) Company will make available to Customer all information necessary to demonstrate compliance with its obligations as a processor specified in Article 28 of the GDPR and allow for and contribute to audits, including inspections, conducted by Customer or another auditor mandated by Customer, consistent with Section 7 of this DPA;
                                                </p>
                                                <p>
                                                      (i) Company will maintain a record of all categories of processing activities carried out on behalf of Customer in accordance with Article 30(2) of the GDPR; and
                                                </p>
                                                <p>
                                                      (j) Company and its representatives will cooperate, on request, with the supervisory authority.
                                                </p>
                                          </div>
                                          <p>
                                                2.3 Customer Processing. Customer will, in its use of the Application, process Personal Data in accordance with the requirements of applicable Data Protection Legislation. Customer’s instructions to Company for the processing of Personal Data will comply with Data Protection Legislation and Customer will have sole responsibility for the accuracy, quality, and legality of Personal Data and the means by which Customer acquired Personal Data. With respect to Personal Data originating in the EEA and Switzerland, if required under applicable data protection law, prior to making any such Personal Data available to Company, directly or through the Application, Customer shall obtain consents from all Customer users of the Application and any other persons whose Personal Data may be transmitted through the use of the Application to the transfer of such Personal Data to the United States or other countries and to the processing of such Personal Data for the purposes described in this DPA and the Agreement. Customer shall also obtain all authorizations and give all notices to data protection authorities within the EEA and Switzerland that are required by applicable data protection laws prior to the transfer of such Personal Data to Company. With respect to Personal Data originating in any other jurisdiction, Customer shall obtain all consents and take all other actions required under applicable data protection laws to make the transfer and processing of such Personal Data as contemplated in this Agreement fully consistent with the requirements of the laws of the jurisdiction where such Personal Data originated.
                                          </p>
                                          <p>
                                                2.4 Processing Instructions. Customer instructs Company to process Personal Data for the following purposes: (i) processing necessary for the use of the Application and in accordance with the Agreement; (ii) processing initiated by Customer’s end users in their use of the Application; and (iii) processing to comply with the other reasonable instructions provided by Customer to Company (e.g., via email or via support requests) where such instructions are consistent with the terms of the Agreement.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>3. RIGHTS OF DATA SUBJECTS</h2>
                                          <p>
                                                3.1 Data Subject Request. Company shall, to the extent legally permitted, promptly notify Customer if Company receives a request from a data subject to exercise the data subject’s right of access, right to rectification, restriction of processing, erasure, data portability, objection to the processing, or its right not to be subject to an automated individual decision making (“Data Subject Request”). Taking into account the nature of the processing, Company shall assist Customer by appropriate technical and organizational measures, insofar as this is possible, for the fulfilment of Customer’s obligation to respond to a Data Subject Request under applicable Data Protection Legislation. In addition, to the extent Customer, in its use of the Application, does not have the ability to address a Data Subject Request, Company shall upon Customer’s request provide commercially reasonable efforts to assist Customer in responding to such Data Subject Request, to the extent Company is legally permitted to do so and the response to such Data Subject Request is required under applicable Data Protection Legislation. To the extent legally permitted, Customer shall be responsible for any costs arising from Company’s provision of such assistance.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>4. SUB-PROCESSORS</h2>
                                          <p>
                                                4.1 Sub-processing. The parties acknowledge that, pursuant to Article 28(2) of the GDPR, a Data Exporter may provide a general written authorization to onward sub-processing by the Data Importer. Accordingly, Customer provides a general authorization to Company, pursuant to Article 28(2) of the GDPR, to engage onward sub-processors, subject to compliance with the requirements in this Section.
                                          </p>
                                          <p>
                                                4.2 Sub-processor List. Company will: (i) upon written request by Customer, make available to Customer a list of all Company subcontractors (“Sub-processors”) that are involved in processing or sub-processing Personal Data in connection with the provision of the Application, if any, together with a description of the nature of services provided by each Sub-processor (“Sub-processor List”); (ii) ensure that all Sub-processors on the Sub-processor List are bound by contractual terms no less onerous than those contained in this DPA; and (iii) be liable for the acts and omissions of its Sub-processors to the same extent Company would be liable if performing the services of each of those Sub-processors directly under the terms of this DPA, except as otherwise set forth in the Agreement.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>5. SECURITY MEASURES</h2>
                                          <p>
                                                Company implements the following technical and organizational security measures with respect to the Personal Data to ensure a level of security appropriate to the risk in accordance with the standards of Article 32 of the GDPR. Company regularly tests, assesses and evaluates the effectiveness of its technical and organization security measures. Company will not materially decrease the overall security of the Application during the term of the Agreement.
                                          </p>
                                          <p>
                                                5.1 Physical Access Controls. Company shall take commercially reasonable measures to (i) prevent physical access to Personal Data, such as security personnel and secured buildings; (ii) prevent unauthorized persons from gaining access to Personal Data, and/or (iii) ensure that the third party data centers it uses to provide the Application are adhering to similar controls.
                                          </p>
                                          <p>
                                                5.2 Data Access Controls. Company shall take commercially reasonable measures to ensure that (i) Personal Data is accessible only by its properly authorized personnel; (ii) Personal Data cannot be read, copied, modified or removed without authorization in the course of processing; and (iii) direct database query access is restricted and application access rights are established and enforced to ensure that the personnel entitled to use a data processing system only have access to the Personal Data to which they have privilege of access.
                                          </p>
                                          <p>
                                                5.3 System Access Controls. Company shall take commercially reasonable measures to prevent Personal Data from being used without authorization. These measures may vary based on the nature of the processing undertaken and may include authentication via passwords and/or two-factor authentication, documented authorization processes, documented change management processes, and/or logging of access on several levels.
                                          </p>
                                          <p>
                                                5.4 Input Controls. Company shall take commercially reasonable measures to ensure that it is possible to check and establish whether and by whom Personal Data has been entered into data processing systems, modified or removed.
                                          </p>
                                          <p>
                                                5.5 Transmission Controls. Company shall take commercial reasonable measures to prevent Personal Data from being read, copied, altered or deleted by unauthorized parties during the transmission thereof.
                                          </p>
                                          <p>
                                                5.6 Logical Separation. Company shall logically segregate Personal Data from different Company customer environments on Company’s systems so Personal Data that is collected for different purposes may be separately processed.
                                          </p>
                                          <p>
                                                5.7 Data Backup. Company shall back-up the databases used to provide the Application on a regular basis, and ensure that such databases are secured, tokenized and/or encrypted so that Personal Data is protected against accidental loss or destruction when hosted by Company.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>6. SECURITY INCIDENT NOTIFICATION</h2>
                                          <p>
                                                If Company becomes aware of any unauthorized or unlawful access to, or acquisition, alteration, use, disclosure, or destruction of, Customer’s Personal Data, including any “personal data breach” as defined in the GDPR (“Security Incident”), Company will notify Customer without undue delay after becoming aware of the Security Incident. Company will also reasonably cooperate with Customer with respect to any investigations and with preparing potentially required notices, and provide any information reasonably requested by Customer in relation to the Security Incident.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>7. AUDITS</h2>
                                          <p>
                                                The parties agree that the audits described in Article 28(h) of the GDPR will be carried out in accordance with the following conditions:
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      (a) Any such audit may be performed no more than once per year unless otherwise agreed to in writing by Customer and Company;
                                                </p>
                                                <p>
                                                      (b) Customer will provide Company with at least 30 (thirty) days’ prior written notice of any audit, which may be conducted by Customer or an independent auditor appointed by Customer that is not a competitor of Company (“Auditor”). The scope of an audit will be limited to Company systems, processes and documentation relevant to the processing and protection of Personal Data, the audit must be performed during Company’s normal business hours, and Auditors will conduct audits subject to any appropriate and reasonable confidentiality restrictions requested by Company;
                                                </p>
                                                <p>
                                                      (c) Prior to the start of an audit, the parties will agree to reasonable time, duration, place and manner conditions for the audit, and a reasonable reimbursement rate payable by Customer to Company for Company’s audit expenses;
                                                </p>
                                                <p>
                                                      (d) If available, Company will provide an Auditor, upon request, with any third party certifications pertinent to Company’s compliance with its obligations under this DPA; and
                                                </p>
                                                <p>
                                                      (e) Customer will promptly notify and provide Company with full details regarding any perceived non-compliance or security concerns discovered during the course of an audit.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <h2>8. GENERAL</h2>
                                          <p>
                                                8.1 Term and Termination. This DPA will remain in force until (i) replaced or repealed by mutual agreement of Customer and Company, (ii) replaced by an alternative data transfer mechanism that applies to the data transfers covered by this DPA and that is recognized under applicable data protection law as adducing adequate safeguards for international data transfers, or (iii) the Agreement is terminated or expires.
                                          </p>
                                          <p>
                                                8.2 Liability. Any claims brought under this DPA will be subject to the same terms and conditions, including the exclusions and limitations of liability, as are set out in the Agreement. Company’s liability to Customer under this DPA will be limited to the same extent as Company’s liability to Customer under the Agreement.
                                          </p>
                                          <p>
                                                8.3 Governing Law. The parties to this DPA hereby submit to the choice of jurisdiction stipulated in the Agreement with respect to any disputes or claims howsoever arising under this DPA, including disputes regarding its existence, validity or termination or the consequences of its nullity; and this DPA and all non-contractual or other obligations arising out of or in connection with it are governed by the laws of the country or territory stipulated for this purpose in the Agreement.
                                          </p>
                                          <p>
                                                8.4 Changes in Data Protection Legislation. Both Company and Customer may propose any variation to this DPA which either party reasonably considers to be necessary to address the requirements of any Data Protection Legislation.
                                          </p>
                                          <p>
                                                8.6 Entire Agreement. This DPA, together with the Agreement, constitutes the entire agreement between the parties and supersedes any other prior or contemporaneous agreements or terms and conditions, written or oral, concerning its subject matter. In case of conflict or inconsistency between this DPA and the Agreement, the following order of precedence shall govern to the extent of the conflict or inconsistency: (i) this DPA; and (ii) the Agreement.
                                          </p>
                                          <p>
                                                8.7 Severability. If any provision of this DPA is determined to be unenforceable by a court of competent jurisdiction, that provision will be severed and the remainder of terms will remain in full effect.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2 className='text-align-center'>EU Model Contractual Clauses (processors)</h2>
                                          <p>
                                                For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection
                                          </p>
                                          <p className='underlined'>
                                                Name of the data exporting organization:
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      The individual or entity using the Application (as defined in the Terms and Conditions) and accepting these clauses.
                                                </p>
                                          </div>
                                          <p>
                                                (the “data exporter”)
                                          </p>
                                          <p className='italic'>
                                                and
                                          </p>
                                          <p className='underlined'>
                                                Name of the data importing organization:
                                          </p>
                                          <div className="padding-left">
                                                <span>Teknikos, Inc.</span><br />
                                                <span>1500 Industry Rd STE T</span><br />
                                                <span>Hatfield, PA 19440</span><br />
                                                <span>United States of America</span>
                                          </div>
                                          <p>
                                                (the data importer)
                                          </p>
                                          <p>
                                                each a “party”; together “the parties”,
                                          </p>
                                          <p>
                                                HAVE AGREED on the following contractual clauses (the Clauses) in order to adduce adequate safeguards with respect to the protection of privacy and fundamental rights and freedoms of individuals for the transfer by the data exporter to the data importer of the personal data specified in <span className='bold' style={{ textDecoration: 'underline' }}>Appendix 1</span>
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 1</p>
                                          <p className='text-align-center italic clause'>Definitions </p>
                                          <p>
                                                For the purposes of the Clauses:
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      (a) <span className='italic'>‘personal data’</span>, <span className='italic'>‘special categories of data’</span>, <span className='italic'>‘process/processing’</span>, <span className='italic'>‘controller’</span>, <span className='italic'>‘processor’</span>, <span className='italic'>‘data subject’</span> and <span className='italic'>‘supervisory authority’</span> shall have the same meaning as in
                                                 Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection
                                                 of individuals with regard to the processing of personal data and on the free movement of such data;
                                                </p>
                                                <p>
                                                      (b) <span className='italic'>‘the data exporter’</span> means the controller who transfers the personal data;
                                                </p>
                                                <p>
                                                      (c) <span className='italic'>‘the data importer’</span> means the processor who agrees to receive from the data exporter personal data intended for processing on his behalf after the transfer in accordance with his instructions and the terms of the Clauses and who is not subject to a third country’s system ensuring adequate protection within the meaning of Article 25(1) of Directive 95/46/EC;
                                                </p>
                                                <p>
                                                      (d) <span className='italic'>‘the subprocessor’</span> means any processor engaged by the data importer or by any other subprocessor of the data importer who agrees to receive from the data importer or from any other subprocessor of the data importer personal data exclusively intended for processing activities to be carried out on behalf of the data exporter after the transfer in accordance with his instructions, the terms of the Clauses and the terms of the written subcontract;
                                                </p>
                                                <p>
                                                      (e) <span className='italic'>‘the applicable data protection law’</span> means the legislation protecting the fundamental rights and freedoms of individuals and, in particular, their right to privacy with respect to the processing of personal data applicable to a data controller in the Member State in which the data exporter is established;
                                                </p>
                                                <p>
                                                      (f) <span className='italic'>‘technical and organizational  security measures’</span> means those measures aimed at protecting personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing.
                                                </p>


                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 2</p>
                                          <p className='text-align-center italic clause'>Details of the transfer</p>
                                          <p>
                                                The details of the transfer and in particular the special categories of personal data where applicable are specified in Appendix 1 which forms an integral part of the Clauses.
                                                </p>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 3</p>
                                          <p className='text-align-center italic clause'>Third-party beneficiary clause</p>
                                          <div className="padding-left">
                                                <p>
                                                      1. The data subject can enforce against the data exporter this Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j), Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party beneficiary.
                                                </p>
                                                <p>
                                                      2. The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where the data exporter has factually disappeared or has ceased to exist in law unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law, as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity.
                                                </p>
                                                <p>
                                                      3. The data subject can enforce against the subprocessor this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.
                                                </p>
                                                <p>
                                                      4.     The parties do not object to a data subject being represented by an association or other body if the data subject so expressly wishes and if permitted by national law.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 4</p>
                                          <p className='text-align-center italic clause'>Obligations of the data exporter</p>
                                          <p>
                                                Obligations of the data exporter
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      (a)     that the processing, including the transfer itself, of the personal data has been and will continue to be carried out in accordance with the relevant provisions of the applicable data protection law (and, where applicable, has been notified to the relevant authorities of the Member State where the data exporter is established) and does not violate the relevant provisions of that State;
                                                </p>
                                                <p>
                                                      (b)     that it has instructed and throughout the duration of the personal data processing services will instruct the data importer to process the personal data transferred only on the data exporter’s behalf and in accordance with the applicable data protection law and the Clauses;
                                                </p>
                                                <p>
                                                      (c)     that the data importer will provide sufficient guarantees in respect of the technical and organizational  security measures specified in Appendix 2 to this contract;
                                                </p>
                                                <p>
                                                      (d)     that after assessment of the requirements of the applicable data protection law, the security measures are appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing, and that these measures ensure a level of security appropriate to the risks presented by the processing and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;
                                                </p>
                                                <p>
                                                      (e)     that it will ensure compliance with the security measures;
                                                </p>
                                                <p>
                                                      (f)     that, if the transfer involves special categories of data, the data subject has been informed or will be informed before, or as soon as possible after, the transfer that its data could be transmitted to a third country not providing adequate protection within the meaning of Directive 95/46/EC;
                                                </p>
                                                <p>
                                                      (g)     to forward any notification received from the data importer or any subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data protection supervisory authority if the data exporter decides to continue the transfer or to lift the suspension;
                                                </p>
                                                <p>
                                                      (h)     to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2, and a summary description of the security measures, as well as a copy of any contract for subprocessing services which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information, in which case it may remove such commercial information;
                                                </p>
                                                <p>
                                                      (i)     that, in the event of subprocessing, the processing activity is carried out in accordance with Clause 11 by a subprocessor providing at least the same level of protection for the personal data and the rights of data subject as the data importer under the Clauses; and
                                                </p>
                                                <p>
                                                      (j)     that it will ensure compliance with Clause 4(a) to (i).
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 5</p>
                                          <p className='text-align-center italic clause'>Obligations of the data importer</p>
                                          <p>
                                                The data importer agrees and warrants:
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      (a)     to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
                                                </p>
                                                <p>
                                                      (b)     that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the instructions received from the data exporter and its obligations under the contract and that in the event of a change in this legislation which is likely to have a substantial adverse effect on the warranties and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
                                                </p>
                                                <p>
                                                      (c)     that it has implemented the technical and organizational  security measures specified in Appendix 2 before processing the personal data transferred;
                                                </p>
                                                <p>
                                                      (d)     that it will promptly notify the data exporter about:
                                                </p>
                                                <div className="padding-left">
                                                      <p>
                                                            (i)any legally binding request for disclosure of the personal data by a law enforcement authority unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation,
                                                      </p>
                                                      <p>
                                                            (ii)any accidental or unauthorized access, and
                                                      </p>
                                                      <p>
                                                            (iii)any request received directly from the data subjects without responding to that request, unless it has been otherwise unauthorized to do so;
                                                      </p>
                                                </div>
                                                <p>
                                                      (e)     to deal promptly and properly with all inquiries from the data exporter relating to its processing of the personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the processing of the data transferred;
                                                </p>
                                                <p>
                                                      (f)     at the request of the data exporter to submit its data processing facilities for audit of the processing activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed of independent members and in possession of the required professional qualifications bound by a duty of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;
                                                </p>
                                                <p>
                                                      (g)     to make available to the data subject upon request a copy of the Clauses, or any existing contract for subprocessing, unless the Clauses or contract contain commercial information, in which case it may remove such commercial information, with the exception of Appendix 2 which shall be replaced by a summary description of the security measures in those cases where the data subject is unable to obtain a copy from the data exporter;
                                                </p>
                                                <p>
                                                      (h)     that, in the event of subprocessing, it has previously informed the data exporter and obtained its prior written consent;
                                                </p>
                                                <p>
                                                      (i)     that the processing services by the subprocessor will be carried out in accordance with Clause 11;
                                                </p>
                                                <p>
                                                      (j)     to send promptly a copy of any subprocessor agreement it concludes under the Clauses to the data exporter.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 6</p>
                                          <p className='text-align-center italic clause'>Liability</p>
                                          <div className="padding-left">
                                                <p> 1. The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations referred to in Clause 3 or in Clause 11 by any party or subprocessor is entitled to receive compensation from the data exporter for the damage suffered. </p>
                                                <p>
                                                      2.     If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data exporter, arising out of a breach by the data importer or his subprocessor of any of their obligations referred to in Clause 3 or in Clause 11, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract of by operation of law, in which case the data subject can enforce its rights against such entity.
                                                </p>
                                                <p>
                                                      The data importer may not rely on a breach by a subprocessor of its obligations in order to avoid its own liabilities.
                                                </p>
                                                <p>
                                                      3.     If a data subject is not able to bring a claim against the data exporter or the data importer referred to in paragraphs 1 and 2, arising out of a breach by the subprocessor of any of their obligations referred to in Clause 3 or in Clause 11 because both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, the subprocessor agrees that the data subject may issue a claim against the data subprocessor with regard to its own processing operations under the Clauses as if it were the data exporter or the data importer, unless any successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law, in which case the data subject can enforce its rights against such entity. The liability of the subprocessor shall be limited to its own processing operations under the Clauses.
                                                </p>
                                                <p>
                                                      4.     The parties agree that if one party is held liable for a violation of the clauses committed by the other party, the latter will, to the extent to which it is liable, indemnify the first party for any cost, charge, damages, expenses or loss it has incurred.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 7</p>
                                          <p className='text-align-center italic clause'>Mediation and jurisdiction</p>
                                          <div className="padding-left">
                                                <p>
                                                      1.     The data importer agrees that if the data subject invokes against it third-party beneficiary rights and/or claims compensation for damages under the Clauses, the data importer will accept the decision of the data subject:
                                                </p>
                                                <div className="padding-left">
                                                      <p>
                                                            (a)to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory authority;
                                                      </p>
                                                      <p>
                                                            (b)to refer the dispute to the courts in the Member State in which the data exporter is established.
                                                      </p>
                                                </div>
                                                <p>
                                                      2.     The parties agree that the choice made by the data subject will not prejudice its substantive or procedural rights to seek remedies in accordance with other provisions of national or international law.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 8</p>
                                          <p className='text-align-center italic clause'>Cooperation with supervisory authorities</p>
                                          <div className="padding-left">
                                                <p>
                                                      1.     The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests or if such deposit is required under the applicable data protection law.
                                                </p>
                                                <p>
                                                      2.     The parties agree that the supervisory authority has the right to conduct an audit of the data importer, and of any subprocessor, which has the same scope and is subject to the same conditions as would apply to an audit of the data exporter under the applicable data protection law.
                                                </p>
                                                <p>
                                                      3.     The data importer shall promptly inform the data exporter about the existence of legislation applicable to it or any subprocessor preventing the conduct of an audit of the data importer, or any subprocessor, pursuant to paragraph 2. In such a case the data exporter shall be entitled to take the measures foreseen in Clause 5 (b).
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 9</p>
                                          <p className='text-align-center italic clause'>Governing Law</p>
                                          <p>
                                                The Clauses shall be governed by the law of the Member State in which the data exporter is established.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 10</p>
                                          <p className='text-align-center italic clause'>Variation of the contract</p>
                                          <p>
                                                The parties undertake not to vary or modify the Clauses. This does not preclude the parties from adding clauses on business related issues where required as long as they do not contradict the Clause.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 11</p>
                                          <p className='text-align-center italic clause'>Subprocessing</p>
                                          <div className="padding-left">
                                                <p>
                                                      1.     The data importer shall not subcontract any of its processing operations performed on behalf of the data exporter under the Clauses without the prior written consent of the data exporter. Where the data importer subcontracts its obligations under the Clauses, with the consent of the data exporter, it shall do so only by way of a written agreement with the subprocessor which imposes the same obligations on the subprocessor as are imposed on the data importer under the Clauses. Where the subprocessor fails to fulfil its data protection obligations under such written agreement the data importer shall remain fully liable to the data exporter for the performance of the subprocessor’s obligations under such agreement.
                                                </p>
                                                <p>
                                                      2. The prior written contract between the data importer and the subprocessor shall also provide for a third-party beneficiary clause as laid down in Clause 3 for cases where the data subject is not able to bring the claim for compensation referred to in paragraph 1 of Clause 6 against the data exporter or the data importer because they have factually disappeared or have ceased to exist in law or have become insolvent and no successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.
                                                </p>
                                                <p>
                                                      3.     The provisions relating to data protection aspects for subprocessing of the contract referred to in paragraph 1 shall be governed by the law of the Member State or country in which the data exporter is established.
                                                </p>
                                                <p>
                                                      4.     The data exporter shall keep a list of subprocessing agreements concluded under the Clauses and notified by the data importer pursuant to Clause 5 (j), which shall be updated at least once a year. The list shall be available to the data exporter’s data protection supervisory authority.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <p className='text-align-center italic clause'>Clause 12</p>
                                          <p className='text-align-center italic clause'>Obligation after the termination of personal data processing services</p>
                                          <div className="padding-left">
                                                <p>
                                                      1.     The parties agree that on the termination of the provision of data processing services, the data importer and the subprocessor shall, at the choice of the data exporter, return all the personal data transferred and the copies thereof to the data exporter or shall destroy all the personal data and certify to the data exporter that it has done so, unless legislation imposed upon the data importer prevents it from returning or destroying all or part of the personal data transferred. In that case, the data importer warrants that it will guarantee the confidentiality of the personal data transferred and will not actively process the personal data transferred anymore.
                                                </p>
                                                <p>
                                                      2.     The data importer and the subprocessor warrant that upon request of the data exporter and/or of the supervisory authority, it will submit its data processing facilities for an audit of the measures referred to in paragraph 1.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <h2 className='text-align-center underlined'>Appendix 1 to the EU Model Contractual Clauses</h2>
                                          <p>
                                                Appendix 1 to the EU Model Contractual Clauses
                                          </p>
                                          <p>
                                                The Member States may complete or specify, according to their national procedures, any additional necessary information to be contained in this Appendix
                                          </p>
                                          <p className="underlined">
                                                Data exporter
                                          </p>
                                          <p>
                                                The data exporters are (please specify briefly your activities relevant to the transfer):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      The data exporter uses the data importer’s Application to support facility management.
                                                </p>
                                          </div>
                                          <p className="underlined">
                                                Data importer
                                          </p>
                                          <p>
                                                The data importer is (please specify briefly activities relevant to the transfer):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      The data importer is a seller of proprietary software.
                                                </p>
                                          </div>
                                          <p className="underlined">
                                                Data subjects
                                          </p>
                                          <p>
                                                The personal data transferred concern the following categories of data subjects (please specify):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      Application users and individuals and entities whose personal data may be provided by Application users.
                                                </p>
                                          </div>
                                          <p className="underlined">
                                                Categories of data
                                          </p>
                                          <p>
                                                The personal data transferred concern the following categories of data (please specify):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      Name, contact phone number, contact e-mail address(es), employee identification number, job title, body temperature, location information, as well as other related information.
                                                </p>
                                          </div>
                                          <p className="underlined">
                                                Special categories of data (if appropriate)
                                          </p>
                                          <p>
                                                The personal data transferred concern the following special categories of data (please specify):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      None
                                                </p>
                                          </div>
                                          <p className="underlined">
                                                Processing operations
                                          </p>
                                          <p>
                                                The personal data transferred will be subject to the following basic processing activities (please specify):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      The data is collected, used, stored and deleted.
                                                </p>
                                          </div>
                                    </section>
                                    <section className='information'>
                                          <h2 className='text-align-center underlined'>Appendix 2 to the EU Model Contractual Clauses</h2>
                                          <p>
                                                This Appendix forms part of the Clauses and must be completed and signed by the parties.
                                          </p>
                                          <p>
                                                Description of the technical and organizational security measures implemented by the data importer in accordance with Clauses 4(d) and 5(c) (or document/legislation attached):
                                          </p>
                                          <div className="padding-left">
                                                <p>
                                                      The data importer has implemented the technical and organizational security measures set forth in the Data Processing Agreement intended to protect personal data against accidental or unauthorized loss, destruction, alteration, disclosure or access, and against all other unauthorized or unlawful forms of processing.
                                                </p>
                                          </div>
                                    </section>
                              </div>
                        </div>
                        <Footer />
                  </div>
            </>
      );
}

export default DataProcessing;
