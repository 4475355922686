import React from 'react';
import Footer from '../../Includes/Footer';
import Header from '../../Includes/Header';

const NotFound = () => {
      return (
            <div className='main-page p-0 not-found-wrapper'>
                  <Header />
                  <div className="content-not-found">
                        <h1>We can't find the page<br /> you are looking for.</h1>
                        <p>Error code: 404</p>
                  </div>
                  <Footer />
            </div>
      );
}

export default NotFound;
