import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../Includes/Header";
import scrollToElem from "../../Libs/smoothScrollPolyfill";

import image006 from "./Images/image006.jpg";
import image008 from "./Images/image008.jpg";
import image010 from "./Images/image010.jpg";
import image012 from "./Images/image012.jpg";
import image014 from "./Images/image014.jpg";
import image016 from "./Images/image016.jpg";
import image018 from "./Images/image018.png";
import image020 from "./Images/image020.jpg";
import image022 from "./Images/image022.jpg";
import image024 from "./Images/image024.jpg";
import image026 from "./Images/image026.jpg";
import image028 from "./Images/image028.jpg";
import image030 from "./Images/image030.jpg";
import image032 from "./Images/image032.jpg";
import image034 from "./Images/image034.jpg";
import image036 from "./Images/image036.jpg";

const RtwDocs = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Power Platform return to the workplace solution | Communify
        </title>
        <meta
          name="description"
          content="Reduce workplace friction to get business back to work"
        />
      </Helmet>
      <div className="terms-wrapper">
        <Header />
        <div className="container">
          <div className="terms-heading cond-heading d-flex flex-column align-items-center">
            <h1 className="terms-title">
              Welcome to Communify Greet with Power Platform return to the
              workplace solution.
            </h1>
            <section className="introduction">
              <p>
                Communify Greet can be configured as a temperature gating add-on
                to the Power Platform return to the work solution.
              </p>
              <p>
                The following instructions assume that you have both RTW and
                Communify set up and properly licensed.
              </p>
              <div className='padding-left'>
                <ul>
                    <li>
                    Communify Greet is compatible with version 1.3 and higher of
                    the Power Platform return to the workplace solution.
                    Installation instructions can be found{" "}
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://docs.microsoft.com/en-us/powerapps/sample-apps/return-to-workplace/deploy"
                    >
                        on the Microsoft Docs
                    </a>{" "}
                    site.
                    </li>
                    <li>
                    For Communify, be sure you have a valid subscription and can
                    log in to the administration portal successfully.
                    </li>
                </ul>
              </div>
            </section>
            <section className="information">
              <h2 className="underlined">Overview</h2>
              <p>
                There are three main steps to getting Communify Greet working
                with Microsoft Return to the Workplace:
              </p>
              <div className="padding-left">
                <p>
                  1.{" "}
                  <Link onClick={e => scrollToElem(`#_Install_the_Communify`)} to={{pathname: "/GreetRTW-Setup", hash: "#_Install_the_Communify"}}>
                    {" "}
                    Installing the Communify Add-On for RTW
                  </Link>
                </p>
                <p>
                  2.{" "}
                  <Link onClick={e => scrollToElem(`#Set-Up-RTW`)} to={{pathname: "/GreetRTW-Setup", hash: '#Set-Up-RTW'}}>
                    Setting up RTW to be linked to Communify
                  </Link>
                </p>
                <p>
                  3.{" "}
                  <Link onClick={e => scrollToElem(`#_Link_Communify_to`)} to={{pathname: "/GreetRTW-Setup", hash: '#_Link_Communify_to'}}>
                    Linking Communify with RTW
                  </Link>
                </p>
                <p>
                  4.{" "}
                  <Link onClick={e => scrollToElem(`#_Setting_Up_and`)} to={{pathname: "/GreetRTW-Setup", hash: '#_Setting_Up_and'}}>
                    Setting Up and Customizing Communify and RTW
                  </Link>
                </p>
              </div>
            </section>
            <section className="information">
              <h2 className="underlined">Requirements</h2>
              <div className="padding-left">
                <p>
                  1. Communify Administration Portal access with Tenant
                  Administrator rights{" "}
                </p>
                <p>2. Valid Communify license</p>
                <p>
                  3. Power Platform return to the workplace solution version 1.3
                  or higher – refer to <a href="https://aka.ms/rtw" rel="noopener noreferrer" target="_blank">the RTW site</a> for more information on
                  installing this.
                </p>
              </div>
            </section>
            <section className="information" id='_Install_the_Communify'>
              <h2 className="underlined">
                Install the Communify Add-On for RTW
              </h2>
              <p>
                When published, you will be able to install the required
                additional components for your RTW with one click from
                AppSource. Again, you must have version 1.3 or higher installed.
              </p>
            </section>
            <section className="information" id='Set-Up-RTW'>
              <h2 className="underlined">
                Set Up RTW to be Linked to Communify
              </h2>
              <span className="bold">Grant Permissions</span>
              <p>
                You need to grant permission to Communify to read and write to
                your Microsoft Dataverse (Common Data Service), the underlying
                data interface to your RTW data. This is so we can look up and
                use facilities and employee attestations, and save temperature
                scan status to the attestation record.
              </p>
              <p>
                To do this, have a global administrator visit this link and sign
                in:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://login.microsoftonline.com/common/adminconsent?client_id=6f328332-2486-4aa3-a263-b572a6125e58&redirect_uri=https://communifyapp.com"
                >
                  https://login.microsoftonline.com/common/adminconsent?client_id=6f328332-2486-4aa3-a263-b572a6125e58&redirect_uri=https://communifyapp.com
                </a>
              </p>
              <p>
                You will be prompted to grant the Communify CDS Link application
                to sign in to your organization and act as an organization user
                (which we will set up shortly).
              </p>
              <p>
                After pressing <span className="bold">Accept</span>, you will be
                taken to the Communify website. Verify that the URL in your
                browser says “admin_consent=True”:
              </p>
              <p>
                <img src={image006} alt="image006" />
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Set up Dynamics 365 User</h2>
              <p>
                Now, we can set up the Dynamics 365 user Communify will use to
                access your RTW data. Log into Dynamics 365 as an administrator.
              </p>
              <p>
                If you are unsure how to do this, try going to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://home.dynamics.com/"
                >
                  https://home.dynamics.com/
                </a>{" "}
                and then selecting the tile that says{" "}
                <span className="bold">Dynamics 365.</span>
              </p>
              <p>
                <img src={image008} alt="image008" />
              </p>
              <p>
                Access the <span className="bold">Settings</span> page for your
                Dynamics 365 installation by clicking the gear icon on the top
                right and selecting{" "}
                <span className="bold">Advanced Settings.</span>
              </p>
              <p>
                <img src={image010} alt="image010" />
              </p>
              <p>
                Click the down arrow next to{" "}
                <span className="bold">Settings</span> in the Dynamics 365 menu
                bar, then choose <span className="bold">Security</span>.
              </p>
              <p>
                <img src={image012} alt="image012" />
              </p>
              <p>
                The Security settings page will load. Click{" "}
                <span className="bold">Users</span> in the top left.
              </p>
              <p>
                Click the main heading to expand the dropdown list of user types
                you can work with, and choose{" "}
                <span className="bold">Application Users.</span>
              </p>
              <p>
                <img src={image014} alt="image014" />
              </p>
              <p>
                Click <span className="bold">+ New</span> in the toolbar to
                display the New Application User form. Be sure the title says{" "}
                <span className="bold">User : Application User.</span>
              </p>
              <p>
                If the title does not say{" "}
                <span className="bold">User : Application User</span>, click the
                title and choose <span className="bold">Application User</span>{" "}
                from the list.
              </p>
              <p>
                <img src={image016} alt="image016" />
              </p>
              <p>
                Fill out the form using Application ID{" "}
                <span className="bold">
                  6f328332-2486-4aa3-a263-b572a6125e58
                </span>{" "}
                – this is the application you authorized earlier.
              </p>
              <p>
                <img src={image018} alt="image018" />
              </p>
              <p>
                We recommend a descriptive full name like “Communify CDS Link”
                and a descriptive email address – note this address does not
                necessarily need to exist in your organization. Filling out the
                Primary Email will populate the User Name field automatically.
              </p>
              <p>
                Save this new user by clicking{" "}
                <span className="bold">Save</span> (not Save {"&"} Close) in the
                toolbar. Note that the Application ID URI and Azure AD Object ID
                fields are locked but will populate automatically when you save.
              </p>
              <p>
                Click <span className="bold">Manage Roles</span> in the toolbar
                and select{" "}
                <span className="bold">
                  Return to the Workplace – Facility Manager
                </span>{" "}
                and{" "}
                <span className="bold">
                  Return to the Workplace – Employee.
                </span>{" "}
                (Note the value for Business Unit will be different for you.)
                Click <span className="bold">OK</span> to save.
              </p>
              <p>
                Don’t close out of Dynamics 365 just yet – there’s one more
                piece of information we need.
              </p>
              <p>
                <img src={image020} alt="image020" />
              </p>
            </section>
            <section className="information" id='_Link_Communify_to'>
              <h2 className="underlined">Link Communify to RTW</h2>
              <p>
                Before we continue,{" "}
                <span className="bold">
                  Communify needs to know where to look for your RTW data.
                </span>{" "}
                Click the down arrow next to Settings in the Dynamics 365
                toolbar and choose <span className="bold">Customizations.</span>
              </p>
              <p>
                <img src={image022} alt="image022" />
              </p>
              <p>
                Choose <span className="bold">Developer Resources</span> on the
                right and then copy and save the{" "}
                <span className="bold">Service Root URL</span> to paste into the
                Communify administration portal later.
              </p>
              <p>
                Note: The beginning part of your URL and possibly the version
                number at the end will differ.
              </p>
              <p>
                <img src={image024} alt="image024" />
              </p>
              <p>
                Log into the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://admin.communifyapp.com/"
                >
                  Communify administration portal.
                </a>
              </p>
              <p>
                On the lower left, click <span className="bold">Settings</span>{" "}
                to access your tenant’s settings page. Scroll down to{" "}
                <span className="bold">Integrations</span> and click the{" "}
                <span className="bold">Connect Account</span> button next to
                Microsoft Power Platform: Return to Work.
              </p>
              <p>
                In the flyout panel that appears on the right, click{" "}
                <span className="bold">Connect</span> and log in with your
                global administrator account. You will review the permissions
                you granted earlier. Click <span className="bold">Accept</span>{" "}
                and you will be taken back to the administration portal.
              </p>
              <p>
                A screen will appear for you to enter the URL you saved above.
                Paste the URL in the field and click{" "}
                <span className="bold">Submit Changes.</span>
              </p>
              <p>Communify is now connected to your RTW installation.</p>
              <p>
                <img src={image026} alt="image026" />
              </p>
            </section>
            <section className="information" id='_Setting_Up_and'>
              <h2 className="underlined">
                Setting Up and Customizing Communify and RTW
              </h2>
              <span className="bold">
                Link a Communify building to a RTW Facility
              </span>
              <p>
                When you create a <span className="bold">Building</span> in
                Communify, be sure to select the corresponding RTW facility it
                corresponds to. Please remember to customize your list of facilities in RTW first.
                This facility assignment will be used by the
                kiosks you assign to this building to verify that a user is at
                the correct building. Selecting a facility will automatically
                set the building name and address, although you are free to edit
                these values.
              </p>
              <p>
                You can also edit this assignment on the Building details page.
              </p>
              <p>
                <img src={image028} alt="image028" />
              </p>
              <p>
                <img src={image030} alt="image030" />
              </p>
              <span className="bold">Kiosk Settings</span>
              <p>
                On the <span className="bold">Kiosks</span> page, you will find
                a button that takes you to{" "}
                <span className="bold">Kiosk Settings.</span> Here you can set a
                passing temperature range for your kiosks, as well as set
                overrides per campus (e.g., if a certain campus has outdoor
                kiosks that may require a different range).
              </p>
              <p>
                Toward the bottom of the{" "}
                <span className="bold">Kiosk Settings</span> page, you will find
                fields to enter custom pass and fail messaging to be shown on a
                kiosk following a temperature scan.
              </p>
              <p>
                <img src={image032} alt="image032" />
              </p>
              <p>
                <img src={image034} alt="image034" />
              </p>
              <span className="bold">RTW Flows</span>
              <p>
                By default, certain Flows execute when a failing temperature
                reading is detected and reported back to RTW. These can be
                accessed by going to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://make.powerapps.com"
                >
                  {" "}
                  https://make.powerapps.com
                </a>{" "}
                and choosing “Solutions” from the left hand menu. Open
                “Extending RTW solution” and note that there are two flows that
                occur when the passing temperature flag is returned as false.
              </p>
              <p>
                These can be cloned to change the way they function (for
                instance, to not open a Case, or to change the text in the
                notification).
              </p>
              <p>
                There are two fields to note that are added to the Employee and
                Guest attestation tables:
              </p>
              <div className="padding-left">
                <ul>
                  <li>
                    {" "}
                    crae4_temperaturecheck (Temperature Check)
                    <div className="padding-left">
                      <p>
                        o This gets set to Yes when a temperature check has been
                        completed.
                      </p>
                    </div>
                  </li>
                  <li>
                    {" "}
                    crae4_validated (Validated)
                    <div className="padding-left">
                      <p>
                        o This gets set to Yes when a temperature check has been
                        completed and the temperature passes; this gets set to
                        No when a temperature check has been completed and the
                        temperature fails.{" "}
                      </p>
                      <p>
                        <img src={image036} alt="image036" />
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default RtwDocs;
