import React from 'react';

import logoFooterLarge from '../../Assets/Images/img-purchase-logo-large.svg';

const Connect = () => {
      return (
            <section id='purchase' className="speak">
                  <h2>Speak with your certified Surface <br />
                        Reseller to purchase Communify. </h2>
                  <div className="radius">
                        <img src={logoFooterLarge} alt="Logo Large"/>
                  </div>
            </section>
      );
}

export default Connect;
