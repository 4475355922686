import React from "react";
import Header from "../../Includes/Header";
import NavBarSupportNew from "./navbar/nav-bar-new";
import SupportMainNew from "./main/mainNew";
import Footer from "../../Includes/Footer";
import { Helmet } from "react-helmet";

import "./guide.css";

const SupportGuideNew = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Support Guide | Communify</title>
        <meta
          name="description"
          content="Reduce workplace friction to get business back to work"
        />
      </Helmet>
      <div className="App wrapper-app">
        <Header />
        <div className="wrapper-guide">
          <div className="container-custom">
            <NavBarSupportNew />
            <SupportMainNew />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SupportGuideNew;
