import * as actionTypes from '../ActionTypes';

const initialState = {
      user: null
};

const authenticationReducer = (state = initialState, action) => {
      switch (action.type) {
            case actionTypes.SET_USER:
                  return {
                        ...state,
                        user: action.user
                  }
            default:
                  return state;
      }
}

export default authenticationReducer;