export const menuItems = [
      {
            name: 'Products',
            hash: '#products'
      },
      {
            name: 'Why Communify?',
            hash: '#why-communify'
      },
      {
            name: 'Purchase',
            hash: '#purchase'
      }
];