import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../Includes/Header";
import Footer from "../../Includes/Footer";

export default function Contact() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Communify</title>
        <meta
          name="description"
          content="Reduce workplace friction to get business back to work"
        />
      </Helmet>
      <div className="terms-wrapper contact-wrapper not-found-wrapper">
        <Header />
        <div className="container">
          <div className="terms-heading cond-heading d-flex flex-column align-items-center">
            <h1 className="terms-title">Contact Us</h1>
            <section className='introduction'>
                <div className="contact-links">
                    <div>
                        <p>Support</p>
                        <a href="mailto:support@communifyapp.com">support@communifyapp.com</a>
                    </div>
                    <div>
                        <p>Sales</p>
                        <a href="mailto:sales@communifyapp.com">sales@communifyapp.com</a>
                    </div>
                </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
