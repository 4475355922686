import React from "react";

import logoFooter from "../../Assets/Images/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const openCokiePreferences = (e) => {
    window.displayPreferenceModal();
  };

  return (
    <footer className="footer-main">
      <div className="footer-container container">
        <div>
          <div>
            {" "}
            <img src={logoFooter} alt="Logo footer" />
          </div>
          <div className="footer-links">
            {" "}
            <Link target="_blank" to="/contact">
              Contact Us
            </Link>
            <Link target="_blank" to="/data-processing-agreement">
              Data Processing Agreement
            </Link>
            <Link target="_blank" to="/terms-and-conditions">
              Terms & Conditions
            </Link>
            <Link target="_blank" to="/privacy">
              Privacy Policy
            </Link>
            <span onClick={openCokiePreferences}>Cookie Preferences</span>
            <Link target="_blank" to="/EULA">
              End User License Agreement
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <span>
            {" "}
            {`© Communify ${new Date().getFullYear()}. All Rights Reserved`}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
