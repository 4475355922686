import React from "react";
import tabletDemo from "../../Assets/Images/img-hero-meet.png";
import tabletDemoGreet from "../../Assets/Images/img-hero-greet.png";
import tabletDemoTeams from "../../Assets/Images/img-hero-teams.png";

const Hero = () => {
  return (
    <div className="container-fluid p-0 hero-wrapper">
      <div className="hero d-flex justify-content-end flex-column align-items-center">
        <div className="heading app-container">
          <h1>
            Reduce workplace friction. <br />
            Get business back to work.
          </h1>
        </div>
        <div className="app-demo pb-0 position-relative app-container">
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-ride="carousel"
            style={{ maxWidth: 770 }}
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={tabletDemo}
                  className="img-fluid position-relative"
                  alt="Tablet Demo"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={tabletDemoGreet}
                  className="img-fluid position-relative"
                  alt="Tablet Demo"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={tabletDemoTeams}
                  className="img-fluid position-relative"
                  alt="Tablet Demo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
