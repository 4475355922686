import React, { useEffect } from 'react';

import logo from '../../Assets/Images/logo.svg';
import customerIcon from '../../Assets/Images/icon-customer-login.svg';
import mobileMenu from '../../Assets/Images/btn-nav-mobile.svg';
import { debounce, toggleMobileMenu } from '../../Utils';
import { useSelector, useDispatch } from 'react-redux';
import { setDimensions } from '../../../Store/Actions';
import { Link } from 'react-router-dom';

const TopBar = () => {
      const dispatch = useDispatch();
      const dimensions = useSelector(state => state.messageLogReducer.dimensions);
      useEffect(() => {
            const debouncedHandleResize = debounce(function handleResize() {
                  dispatch(setDimensions({
                        height: window.innerHeight,
                        width: window.innerWidth
                  }))
            }, 1000)

            window.addEventListener('resize', debouncedHandleResize)

            return _ => {
                  window.removeEventListener('resize', debouncedHandleResize)
            }
      })

      return (
            <div>
                  <div className="col d-flex justify-content-md-center justify-content-sm-between  pt-3 pl-0 pr-0 position-relative">
                        <div className="website-logo">
                              <Link to='/'> <img src={logo} alt="Website Logo" /> </Link>
                        </div>
                        {dimensions && (dimensions.width > 650 ?
                              <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_ADMIN_URL}>
                                    <div className="customer-login position-absolute d-flex">
                                          <div className="customer-icon mr-2">
                                                <img src={customerIcon} alt="Cusomter Icon" />
                                          </div>
                                          <div className="customer-text ">
                                                {/* <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_ADMIN_URL}>Customer Login</a> */}
                                                Customer Login
                                          </div>
                                    </div>
                              </a>
                              : <div onClick={e => toggleMobileMenu('expand')} className='customer-login position-absolute d-flex mobile-menu-open'>
                                    <img src={mobileMenu} alt='mobile-menu' />
                              </div>)}
                  </div>
            </div>
      );
}

export default TopBar;