import React from 'react';
import imgConsent from '../images/communify-admin-consent-true@3x.png';
import imgOfficeStrct from '../images/img-office-structure@3x.png';

const SupportMainNew = () => {
  return (
    <div className="main">
      <h1 id="supportGuideId">Support Guide</h1>
      <p className="LModify">Last Modified: April 30, 2021</p>

      <h2 id="toWelcomeToComunity">Welcome to Communify</h2>

      <p>
        The Communify platform and its product suite facilitate workplace productivity by providing
        centralized access to information, behind-the-scenes intelligence to drive decision making,
        and intuitive information displays.
        <br />
        <br />
        The Communify Administration Portal is the centralized management tool for the Communify
        product ecosystem. Role-based access control allows users across the organization to manage
        the Communify app endpoints, settings, properties, digital signage, and view the status of
        Communify products across six sections of the portal:
      </p>

      <ul className="blue-and-underlined">
        <li>
          <a href="#toSettings">Settings</a>
        </li>
        <li>
          <a href="#toOffices">Offices</a>
        </li>
        <li>
          <a href="#toDigitalSignage">Digital Signage</a>
        </li>
        <li>
          <a href="#toSubscriptions">Subscriptions</a>
        </li>
        <li>
          <a href="#toDevices">Devices</a>
        </li>
        <li>
          <a href="#toUsers">Users</a>
        </li>
      </ul>

      <p>Communify products centrally managed from the Communify Administration Portal include: </p>

      <ul className="blue-and-underlined">
        <li>
          <a href="#toCommunifyMeet">Communify Meet calendar hall display</a>
        </li>
        <li>
          <a href="#toCommunifyGreet">Communify Greet temperature gating kiosk</a>
        </li>
        <li>
          <a href="#toCommunifySeat">Communify Seat flexible workspace display</a>
        </li>
        <li>
          <a href="#toCommunifyApp">
            Communify App employee reservation management for Microsoft Teams
          </a>
        </li>
      </ul>

      <h3>Prerequisites</h3>
      <p>
        Communify works with your existing Microsoft infrastructure. Different product types have
        different requirements:{' '}
      </p>
      <h4>M365 Endpoints – Meet and Seat</h4>
      <ul type="circle">
        <li>
          <p>Microsoft 365 with full Exchange Online or</p>
        </li>
        <li>
          <p>
            Hybrid Exchange deployments that meet{' '}
            <a
              href="https://docs.microsoft.com/en-us/graph/hybrid-rest-support"
              target="_blank"
              rel="noopener noreferrer"
            >
              these requirements
            </a>
          </p>{' '}
        </li>
      </ul>
      <h4>Return to Work / School Endpoints - Greet and Seat </h4>
      <ul type="circle">
        <li>
          <p>
            Microsoft Return to Work v1.3 or Return to School v1.0 or higher. Deployment
            instructions <a href="#toSettings_Connect_To_Platform">here</a>.
          </p>
        </li>
        <li style={{ marginLeft: 78 }}>
          <p>This requires a Power Platform subscription, and optionally Power BI.</p>{' '}
        </li>
      </ul>

      <h4>Permissions</h4>

      <p>
        Both M365 and RTW/RTS integrations require the approval of Azure Enterprise applications
        that allow Communify to access data stored in each.
      </p>

      <h3>M365:</h3>
      <h4>Calendars.ReadWrite </h4>

      <p>
        Gives Communify access to calendars of users and rooms in the organization to check
        schedules and create meetings, including Teams meetings. 
      </p>

      <h4>MailboxSettings.Read</h4>

      <p>
        Gives Communify access to users’ settings such as time zone to help tailor the system to
        their preferences. 
      </p>

      <h4>Place.Read.All</h4>

      <p>Gives Communify access to Outlook Places to read room/space information.  </p>

      <h4>User.Read.All </h4>

      <p>
        Gives Communify access to user information for display, as well as to use in
        suggestion-making for event locations  Optionally, if you want additional Communify
        functionality facilitated by a service account, you must grant these permissions: 
      </p>

      <h4>Place.ReadWrite.All </h4>

      <p>Allows Communify to update Outlook Places. </p>

      <h4>Offline_Access </h4>

      <p>
        Allows Communify to retain service account access over an extended period of time without
        constantly having to log in.   
      </p>

      <h3>RTW/RTS:</h3>
      <h4>Application Permissions </h4>
      <p>
        You grant the Dynamics CRM <i>user_impersonation</i> permission to the Communify
        application, which allows us to interact with your Dataverse as an application user. 
      </p>

      <h4>Dynamics User Roles </h4>
      <p>
        The application user you create for the Communify application will need to be assigned the 
        <i>Return to the Workplace - Facility Manager</i> and 
        <i>Return to the Workplace – Employee</i> roles.  
      </p>

      <h3>New Tenant Activation</h3>

      <p>
        New tenants may be activated from{' '}
        <a href="https://admin.communifyapp.com/register" target="_blank" rel="noopener noreferrer">
          https://admin.communifyapp.com/register
        </a>{' '}
        by entering organization and tenant owner details and a valid Communify subscription code.{' '}
      </p>

      <span>
        Note: New tenants activating IP Filtering and Data Segmentation features will follow a
        custom registration process provided with their Enterprise Agreement.{' '}
      </span>

      <h3>Communify Administration Portal</h3>

      <p>
        The Communify Administration Portal can be accessed at{' '}
        <a href="https://admin.communifyapp.com" target="_blank" rel="noopener noreferrer">
          https://admin.communifyapp.com
        </a>
        . Customers with IP Filtering and Data Segmentation will have a custom URL.
      </p>

      <h2 id="toSettings">Settings</h2>

      <p>
        The Settings section of the Communify Administration Portal provides for tenant-wide
        configurations, resource import, and general management. Only Tenant Owner and Tenant
        Administrator roles are granted permissions to view and/or modify Settings.{' '}
      </p>
      <p>
        The Settings section is broken down into tab-based navigation to group general, product,
        integration, and security configurations.
      </p>

      <h3>Product Configurations</h3>

      <p>
        Each Communify product features a set of configurations that can be modified by selecting
        the product name from the Products dropdown. Product visibility across the Communify
        Administration portal, product-specific settings, and individual features for each endpoint
        can be configured from each product section.
      </p>

      <h3>Service Integrations</h3>

      <p>
        Communify products are enabled through service integrations with your existing cloud
        Microsoft deployment. By leveraging the{' '}
        <a
          href="https://docs.microsoft.com/en-us/graph/overview"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Graph API
        </a>{' '}
        accessed through the Communify{' '}
        <a
          href="https://docs.microsoft.com/en-us/azure/active-directory/develop/app-objects-and-service-principals"
          target="_blank"
          rel="noopener noreferrer"
        >
          enterprise application
        </a>
        , Communify products can securely access schedule, booking, contact, and resource data in
        Exchange and Dynamics. The settings, properties, and rules configured in Exchange and
        Dynamics are augmented by configuration in the Communify Administration Portal that are not
        otherwise exposed in Exchange.{' '}
      </p>

      <h4 id="toSettings_Connect_To_365">Connect to Microsoft 365 </h4>

      <p>
        Communify is installed as an Azure Enterprise Application. A Global Administrator account in
        Microsoft 365 is required to install and grant permission to Communify to interact with your
        organization’s calendars. The major benefit of granting Meet access this way is that it is a
        one-time process that requires no maintenance as new rooms are added, however Exchange data
        cannot be modified from the Communify Administration Portal.
      </p>

      <h4 id="toSettings_Connect_To_Acc">Connect with a Service Account </h4>

      <p>
        To enable editing of Exchange-based data and for Communify to write changes back to
        Exchange, Microsoft requires a specific account (rather than the Communify application as a
        whole) for this feature. This can be a dedicated Exchange service account created
        specifically for this purpose.{' '}
      </p>

      <h4>Designate Meeting Owner</h4>

      <p>
        Add a designated meeting owner email address to function as the user authorized to create
        and modify meetings from the Meet calendar hall display app. This is typically a generic
        user to prevent room resources from owning endpoint-generated reservations.{' '}
      </p>

      <h3 id="toSettings_Connect_To_Platform">
        Connect to Power Platform Return to Work / School [Communify Greet and Communify Seat RTW
        Endpoints Only]
      </h3>

      <p>
        Communify Greet and Seat can be configured as endpoints on the Power Platform Return to Work
        / School solutions, and require service integration to work.
        <br />
        <br />
        There are three main steps to getting Communify Greet working with Microsoft Return to Work
        / Return to School:
      </p>

      <ol>
        <li>
          <p>Installing the Communify Add-On for RTW / RTS</p>
        </li>
        <li>
          <p>Setting up RTW / RTS to be linked to Communify</p>
        </li>
        <li>
          <p>Linking Communify with RTW / RTS</p>{' '}
        </li>
      </ol>

      <p>Requirements: </p>

      <ol>
        <li>
          <p>Communify Administration Portal access with Tenant Administrator rights</p>
        </li>
        <li>
          <p>Valid Communify license</p>
        </li>
        <li>
          <p>
            Power Platform Return to the Work solution version 1.3 or higher or Power Platform
            Return to School solution version 1.0 or higher. Please be sure this is{' '}
            <a
              href="https://docs.microsoft.com/en-us/dynamics365/industry/return-to-workplace/deploy"
              target="_blank"
              rel="noopener noreferrer"
            >
              installed and configured
            </a>{' '}
            before continuing.
          </p>
        </li>
      </ol>

      <h4>Grant Permissions </h4>

      <p>
        Administrators will need to grant permission to Communify to read and write to the Microsoft
        Dataverse (Common Data Service), the underlying data interface to your RTW / RTS data. This
        is so Communify can look up and use facilities and employee attestations, and save
        temperature scan status to the attestation record.
        <br />
        <br />
        To do this, a Global Administrator must visit{' '}
        <a
          href="https://login.microsoftonline.com/common/adminconsent?client_id=6f328332-2486-4aa3-a263-b572a6125e58&redirect_uri=https://communifyapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          this link
        </a>{' '}
        and sign in. Administrators will be prompted to grant the Communify CDS Link application to
        sign into the organization and act as an organization user (which will be set up in later
        steps).
        <br />
        <br />
        After pressing <strong>Accept</strong>, administrators will be navigated to the Communify
        website. Verify that the URL in your browser says “admin_consent=True”:
      </p>

      <img src={imgConsent} alt="admin_consent_img" />

      <h4>Set up Dynamics 365 User </h4>

      <p>
        Administrators will next need to set up the Dynamics 365 user that Communify will use to
        access your RTW / RTS data. Log into Dynamics 365 as an administrator.
      </p>

      <h4>Step-By-Step Dynamics 365 User Setup</h4>

      <ol>
        <li>
          <p>
            Go to{' '}
            <a href="https://make.powerapps.com" target="_blank" rel="noopener noreferrer">
              https://make.powerapps.com
            </a>
            . Access the <strong>Settings</strong> page for your Dynamics 365 installation by
            clicking the gear icon on the top right and selecting <strong>Advanced Settings</strong>
            .
          </p>
        </li>

        <li>
          <p>
            Click the down arrow next to <strong>Settings</strong> in the Dynamics 365 menu bar,
            then choose <strong>Security</strong>.
          </p>
        </li>

        <li>
          <p>
            The Security settings page will load. Click <strong>Users</strong> in the top left.
          </p>{' '}
        </li>

        <li>
          <p>
            Click the main heading to expand the dropdown list of user types you can work with, and
            choose <strong>Application Users.</strong>
          </p>
        </li>

        <li>
          <p>
            Click + <strong>New</strong> in the toolbar to display the New Application User form. Be
            sure the title says <strong>User : Application User</strong>.
            <br />
            <br />
            If the title does not say <strong>User : Application User</strong>, click the title and
            choose <strong>Application User</strong> from the list.
          </p>
        </li>

        <li>
          <p>
            Fill out the form using Application ID 6f328332-2486-4aa3-a263-b572a6125e58 – this is
            the application you authorized earlier.
            <br />
            <br />
            Depending on your version of Dynamics, you may be able to edit additional fields. If so,
            we recommend a descriptive full name like “Communify CDS Link” and a descriptive email
            address – note this address does not necessarily need to exist in your organization.
            Filling out the Primary Email will populate the User Name field automatically.
          </p>
        </li>
        <li>
          <p>
            Save this new user by clicking <strong>Save</strong> (not Save & Close) in the toolbar.
            Note that the Application ID URI and Azure AD Object ID fields are locked but will
            populate automatically when you save.
          </p>{' '}
        </li>

        <li>
          <p>
            Click <strong>Manage Roles</strong> in the toolbar and select{' '}
            <strong>Return to the Workplace – Facility Manager</strong> and{' '}
            <strong>Return to the Workplace – Employee.</strong> (Note the value for Business Unit
            will be different for you.) If you are using Return to School, use the appropriate
            roles. Click <strong>OK</strong> to save.
          </p>
        </li>
      </ol>

      <h4>Link Communify with RTW / RTS</h4>

      <p>
        Communify needs to know where to look for your RTW / RTS data, which is mapped from the
        Service Root URL.
        <br />
        <br />
        In Dynamics:
      </p>

      <ol>
        <li>
          <p>
            Click the down arrow next to Settings in the Dynamics 365 toolbar and choose{' '}
            <strong>Customizations</strong>.
          </p>
        </li>

        <li>
          <p>
            Choose <strong>Developer Resources</strong> on the right and then copy and save the{' '}
            <strong>Service Root URL</strong> to paste into the Communify administration portal.
          </p>
        </li>
      </ol>

      <span>
        Note: New tenants activating IP Filtering and Data Segmentation features will follow a
        custom registration process provided with their Enterprise Agreement.{' '}
      </span>

      <p>In the Communify Administration Portal:</p>

      <ol>
        <li>
          <p>
            From the Settings page, select the Microsoft Power Platform “
            <strong>Connect Account</strong>” button.
          </p>
        </li>

        <li>
          <p>
            In the form, select “<strong>Connect</strong>” and log in with your global administrator
            account to review and accept the permissions granted previously.
          </p>{' '}
        </li>

        <li>
          <p>
            Paste the <strong>Service Root URL</strong> in the required field of the screen that
            appears, and select “<strong>Submit Changes</strong>
            ”.
          </p>{' '}
        </li>
      </ol>

      <p>Communify is now connected to your RTW / RTS installation.</p>

      <h3>Security settings</h3>

      <h4>Single Sign-On (SSO) </h4>

      <p>
        Single Sign-On (SSO) will disable Communify-based login credentials and enable users to log
        into the Communify Administration Portal with their Microsoft M365 username and password.
      </p>

      <h4>Two-Factor Authentication (2FA) </h4>

      <p>
        By enabling 2FA, Communify administrators will be required to configure a secondary
        authentication method and enter their password and authentication code in order to sign in
        to the Communify Administration Portal.
      </p>

      <h4>Domain restrictions</h4>

      <p>
        New user invitations may be restricted to only approved domains by adding one or more
        domains. When domain restrictions are configured, new user invitations to email addresses
        not included in the domain list will not be allowed. Multiple allowable domains can be
        specified.
      </p>

      <h4>IP Filtering</h4>

      <p>
        Communify customers subscribed to IP Filtering can apply IP Filter rules to enable accepted
        ranges for devices accessing the Communify Administration Portal and Communify endpoints.
        All IP ranges are accepted by default. By configuring IP rules, new ranges will be rejected.
      </p>

      <span>
        Note: If you apply an IP filter rule, be sure to apply a rule containing your current
        external IP first! Please use caution when applying IP filters. Should your current IP
        address fall outside of the applied ranges, you will lose access to the Communify
        Administration Portal. Contact{' '}
        <a href="mailto:support@communifyapp.com">support@communifyapp.com</a> if you are
        unintentionally locked out of your account.
      </span>

      <h2 id="toOffices">Offices</h2>

      <p>
        In Communify, an organization’s office is made up of campuses, buildings, and endpoint
        entities like Meet rooms, Greet kiosks, and Seat workspaces. Please reference the chart
        below demonstrating how a company may be organized. At least one campus and one building
        must be configured in order to add endpoint entities.{' '}
      </p>

      <h3>Bulk Import</h3>

      <p>
        The Offices section can be auto-populated by uploading a complete .CSV with Campuses,
        Offices, Buildings, and endpoint entities from the <strong>Settings</strong> section.
      </p>

      <img src={imgOfficeStrct} alt="structure_img" />

      <h3>Campuses</h3>

      <p>
        A Campus is a collection of assigned buildings. Some customers refer to campuses by a formal
        campus name, city, or region.
        <br />
        <br />
        To add a Campus, select the "Add Campus" button from <strong>Offices {'>'} Campuses</strong>
        . Once you have named your campus and added an address, buildings may be assigned from
        Campus Details or from Building Details.{' '}
      </p>

      <h3>Campus Details</h3>

      <p>
        For tenants with Occupancy Restrictions enabled, administrators may adjust the maximum
        occupancy for a campus from the Campus Details page. This feature allows a percentage to be
        applied to the capacity of Communify-enabled spaces, reducing the functional reservation
        limit in accordance with social distancing policies and reopening plans.
      </p>

      <h3>Buildings</h3>

      <p>
        A Building represents the physical location of endpoint entities like rooms, kiosks, and
        workspaces. For most organizations, a building will represent single office address or
        location. Buildings must be assigned to a campus, and at least one building must be
        configured before endpoint entities can be added.
        <br />
        <br />
        To add a building, select the “Add Building” button from{' '}
        <strong>Offices {'>'} Buildings</strong> or select the “Add New Building” option from the
        “Buildings Assigned” section of a Campus Details page.
        <br />
        <br />
        Buildings can be modified with additional properties that will be used by assigned
        endpoints, digital signage, and in search from the Communify App for Microsoft Teams.
      </p>

      <h4 id="toOffices_RTW_RTS">Link a Communify Building to an RTW / RTS Facility </h4>

      <p>
        For organizations integrating Power Platform, when creating a Building in Communify, select
        the corresponding RTW / RTS facility from the dropdown provided. This facility assignment
        will link a Communify building with a Power Platform facility. Selecting a facility will
        automatically set the building name and address, although you are free to edit these values.
        This assignment can be edited from the Building Details page.
      </p>

      <h4>Floors</h4>

      <p>
        Floors are an optional way to create an additional organizational layer within a building.
        Floors can be added to a building by selecting to "Add Floor" button from the Building
        Details page. Floors can be rearranged by selecting the “Reorder Floors” button. After a
        floor has been created, some Communify endpoints can be assigned to a floor.{' '}
      </p>

      <h4>Operating Hours</h4>

      <p>
        From a Building Details page, admins can edit a building’s work hours, which will adjust the
        agenda view in the Meet calendar hall display and provide enhanced recommendations in the
        Communify App.
      </p>

      <h3>Groups</h3>

      <p>
        Endpoint entities like rooms, kiosks, and workspaces can be organized into custom groups to
        facilitate targeted digital signage targeting for users in specific locations, or by
        department, team, and work group. Groups can include a collection of endpoint entities
        across one or more buildings in a single campus.
        <br />
        <br />
        There are two types of groups in the Communify Administration portal:
      </p>

      <ul>
        <li>
          <strong>Custom room groups</strong> are user-generated. These groups can include any
          collection of rooms and/or kiosks across one or more buildings in a single campus.
        </li>

        <li>
          <strong>Floor room groups</strong> are system-generated. A floor group is automatically
          created for each floor of a building with more than one room or kiosk assigned. Floors can
          be created by visiting a Building’s detail page.
        </li>
      </ul>

      <p>
        Group can be added by selecting the "Add Groups" button from{' '}
        <strong>Offices {'>'} Groups</strong>. Use the form to add a name and assign a campus. Upon
        creating the group, visit the custom group detail page to add and edit endpoint entity
        membership.
      </p>

      <h4>Granular Signage Group Assignment Management</h4>

      <p>
        Some locations and endpoints may require special signage control. An example would be to
        ensure public-facing endpoints, like Greet kiosks, do not display signage groups containing
        widely-distributed internal messaging.
        <br />
        <br />
        Digital signage group inheritance can be adjusted in a custom group by navigating to{' '}
        <strong>
          Offices {'>'} Groups {'>'} Group detail
        </strong>{' '}
        or floor groups from{' '}
        <strong>
          Offices {'>'} Buildings {'>'} Building Detail Page {'>'} Floor Detail Page
        </strong>
        . Find the Digital Signage section and select the “Edit Digital Signage Group Visibility”
        button to manage distribution sources.
      </p>

      <h2 id="toDigitalSignage">Digital Signage</h2>

      <p>
        Digital Signage is a core feature of the Communify platform, providing centralized
        management and granular distribution of messaging across all Communify endpoints.
        <br />
        <br />
        Administrators create slides and add slides to signage groups, which can be published across
        all endpoints or to one or more campus, building, floor, group, or endpoint.
      </p>

      <h3>Messaging Slides</h3>

      <p>
        Messaging slides are the content displayed in the digital signage area of Communify
        endpoints. Slides may be uploaded as complete, pre-designed images or can be authored in the
        Communify Administration Portal with a background image, header, and message.
        <br />
        <br />
        To add a messaging slide, select the “Add Slide” button from{' '}
        <strong>Digital Signage {'>'} Slides</strong>. Messaging slides allow for a slide name,
        slide background image, title, and message. Slides can be added, removed, and edited.
      </p>

      <ul>
        <li>Recommended Background image size: 1200px by 976px </li>

        <li>
          Remember to <strong>Publish Changes</strong> after modifying a slide. Changes will not be
          visible on the meeting room displays until a slide is published.{' '}
        </li>

        <li>Endpoints sync for new changes can take up to one hour. </li>
      </ul>

      <h3>Signage Groups</h3>

      <p>
        Slides are added to Signage Groups, which is published to Communify endpoints. Signage
        groups can be assigned to the Tenant for display on all Communify endpoints, or to one or
        more campus, building, floor, custom group, or endpoint.{' '}
      </p>

      <ul>
        <li>
          Once assigned to a location, Communify endpoints will cycle through the slides in the
          signage group. When one signage group is assigned to a location, slides will cycle and
          repeat continually. When multiple signage groups are applied to a location, the slides in
          each signage group will cycle once before repeating.
        </li>
      </ul>

      <p>
        To add a signage group, select the “Add Signage Group” button from{' '}
        <strong>Digital Signage {'>'} Signage Groups</strong>. Signage Groups can be assigned to the
        entire tenant, or one or more campus, building, floor, group or endpoint. Upon creating the
        Signage Group, visit the detail page to add, remove, and reorder slides, and adjust location
        assignments.
        <br />
        <br />
        If there are no signage groups assigned to an endpoint, a Date & Time slide will be
        displayed. Date & Time slides can be configured in{' '}
        <strong>Digital Signage {'>'} Signage Settings</strong>.
      </p>

      <h4>Granular Signage Group Assignment Management </h4>

      <p>
        Some locations and endpoints may require special signage control. An example would be to
        ensure public-facing endpoints, like Greet kiosks, do not display signage groups containing
        widely-distributed internal messaging.
        <br />
        <br />
        Digital signage group inheritance can be adjusted in a custom group by navigating to{' '}
        <strong>
          Offices {'>'} Groups {'>'} Group detail
        </strong>{' '}
        or floor groups from{' '}
        <strong>
          Offices {'>'} Buildings {'>'} Building Detail Page {'>'} Floor Detail Page
        </strong>
        . Find the Digital Signage section and select the “Edit Digital Signage Group Visibility”
        button to manage distribution sources.
      </p>

      <h3>Signage Settings</h3>

      <p>
        Organizations can configure their Communify endpoints, default digital signage, and signage
        behaviors from <strong>Digital Signage {'>'} Signage Settings</strong>.
      </p>

      <h4>Endpoint branding</h4>

      <p>
        Administrators can update the visual appearance of their endpoints to reflect the
        organization’s logo, and the colors of text, buttons, and room status indicators.
      </p>

      <ul>
        <li>
          A PNG (.png) with transparent background logo is recommended, however a JPEG (.jpg) image
          may also be uploaded to display your organization’s logo. Recommended logo height is 64px.{' '}
        </li>

        <li>The color of endpoint status indicators can be adjusted by using the color picker. </li>

        <li>
          Adjusting the font color will affect the fonts, icons, and buttons on Communify endpoints.{' '}
        </li>
      </ul>

      <h4>Slide dwell duration</h4>

      <p>
        The amount of time a slide is visible before transitioning to the next slide can be
        controlled by the “Slide Dwell Duration” drop down. This is a global setting and will apply
        to all slides, including the date & time slide.
      </p>

      <h4>Date & time slide settings</h4>

      <p>
        Date & time slides are the default slide displayed on Communify endpoints and includes the
        local time, date, and a custom image. Administrators can configure the frequency with which
        date & time slides are displayed in relation to messaging slides.
      </p>

      <ul>
        <li>Recommended background image size: 1200px by 976px. </li>

        <li>
          Remember to <strong>Publish Changes</strong> after modifying signage settings. Published
          changes may take up to one hour to refresh on endpoints.{' '}
        </li>
      </ul>

      <h2 id="toSubscriptions">Subscriptions</h2>

      <p>
        Subscriptions and licenses for Communify are purchased through Communify resellers.
        Communify licenses are volume-based, with one seat in a license used to authorize one
        Communify endpoint. When a license expires, associated endpoints will be deactivated until a
        valid license is applied.
      </p>

      <h3>Manage subscription & licensing</h3>

      <p>
        Subscriptions and licenses for Communify are typically purchased through your reseller.
        <br />
        <br />
        Administrators can manage their organization's subscriptions and view licenses from the{' '}
        <strong>Subscriptions</strong> section of the Communify Administration Portal. Meet
        subscriptions are activated when the first Meet calendar hall display application is
        activated.
        <br />
        <br />
        To add a subscription, click the "Add Subscription" button from{' '}
        <strong>Subscriptions</strong>. A custom name may be added for organizational reference.
      </p>

      <h3>Subscription Groups </h3>

      <p>
        Subscriptions can be sub-divided into named groups with a number of seats assigned. These
        groups are displayed during the device activation process, and can be used to designate a
        set of seats for allocation to a specific Communify product type and / or campus location.
      </p>

      <h3>Subscription Renewal </h3>

      <p>
        Renewal of an existing subscription can be completed by selecting the “Renew Subscription”
        button from the License Details section of a Subscription Details page and entering a
        subscription renewal keycode in the entry field of the fly-out.
      </p>

      <h3>End of subscription</h3>

      <p>
        When your subscription expires, all assigned Communify endpoints will be deactivated until a
        new subscription is applied or the existing subscription is renewed.
      </p>

      <h2 id="toDevices">Devices</h2>

      <p>
        Communify endpoints are deployed exclusively on Microsoft Surface products. Endpoint
        entities like rooms, kiosks, and workspaces that are configured in the Communify
        Administration Portal are paired to their corresponding Meet, Greet, and Seat apps. When a
        Communify app has been paired and licensed, it will activate the device.
      </p>

      <span>
        Note: Communify endpoint apps are intended to be run in Single-App Kiosk Mode (Assigned
        Access), which can be configured through Windows Settings.
      </span>

      <h3>Pair a device</h3>

      <p>
        To pair a device with the Communify Administration Portal, select the “Pair Device” button
        in <strong>Devices {'>'} Active Devices</strong>. A 2-step pairing process is employed to
        ensure both security in initial pairing and long-term pairing resiliency. To complete this
        process, buildings, campuses, offices, and corresponding endpoint entities like rooms,
        kiosks, or workspaces must already be configured, and a valid a subscription must already be
        added to the tenant.{' '}
      </p>

      <h4>Step-By-Step Pairing</h4>

      <ol>
        <li>
          <p>Launch the Communify endpoint application app on the device to be paired. </p>
        </li>

        <li>
          <p>
            In the application, input the <strong>8-character tenant</strong> code displayed in the
            Communify Administration Portal to associate the device to your organization and select
            the “Next” button. The tenant code will refresh after a timeout.
          </p>{' '}
        </li>

        <li>
          <p>
            Enter the <strong>device PIN</strong> shown in the app into the Communify Administration
            Portal and select the “Next” button.
          </p>{' '}
        </li>

        <li>
          <p>
            You will be prompted to optionally assign this device to an existing endpoint entity.
            You can find the room kiosk by selecting a campus and building.
          </p>{' '}
        </li>

        <li>
          <p>
            Finally, on the last page of the setup process, you can associate a subscription with
            this device by tapping on the toggle switch next to the desired subscription.
          </p>{' '}
        </li>
      </ol>

      <p>
        Once pairing is complete, the endpoint is successfully paired to the Communify
        Administration Portal. Devices can be named, assigned to a campus, activated with a valid
        subscription, and associated with a room or kiosk.
      </p>

      <span className="span-margin-bt-thiner-than-usual">
        Note: Unpaired devices that have been previously associated with an organization by entering
        the tenant code will skip Step 2. In this case, select the “Next” button in the Communify
        Administration Portal and proceed to step 3.
      </span>

      <p>
        Troubleshooting Tip: If a Device PIN is not displayed in the endpoint app, this indicates a
        sync issue. Devices must have “Set Time Automatically” selected in Windows Date & Time
        Settings in order to sync with Communify.
      </p>

      <h3>Device Status</h3>

      <p>
        Administrators can monitor device status alerts from the Communify Administration Portal
        dashboard and view individual device status and logs. Devices are reported as offline if
        they do not return a status.
        <br />
        <br />
        Devices can be filtered by selecting from the “Device Type,” “Status,” and “Activation
        State” fields on the Devices page.
        <br />
        <br />
        Pending devices are endpoints that are missing endpoint entity assignment or licensing.
      </p>

      <h3>Editing endpoint assignment</h3>

      <p>
        To add or edit a device’s endpoint entity assignment, navigate to the Device Detail page and
        then select the “Edit Assignment” button.
      </p>

      <h3>Assign product licensing</h3>

      <p>
        To add or edit a license on a device, navigate to the Device Detail page and select the
        “Manage Licensing” button.
      </p>

      <h2 id="toUsers">Users</h2>

      <p>
        Role-based access control in the Communify Administration portal allows for administrators
        to add users with read and write rights limited both by role and location. Multiple roles
        and locations may be assigned to a single user to build customized access.
      </p>

      <h3>Add a user</h3>

      <p>
        To add a user, navigate to <strong>Users</strong> and select the “Add User” button. Provide
        an email address and select a role to send an invite. Visit a user’s detail page to modify
        and add roles, cancel invitations, and remove users.
      </p>

      <span>
        Note: Domain restriction limits invitations only to email addresses matching your
        organization’s domain. To add additional domains, navigate to Settings and select the “Add
        Domain” button under the Domain Restrictions setting.
      </span>

      <h3>User Roles</h3>

      <h4>Tenant Administrator</h4>

      <p>
        Tenant administrators can view and modify all sections of the Administration portal and can
        add, modify, and remove IT, Facilities and Digital signage roles.
      </p>

      <h4>IT Administrator</h4>

      <p>
        IT Admins can add devices and pair to existing endpoints. IT Admins can view all office
        sections. Location-based restriction may be applied.
      </p>

      <h4>Facilities Administrator</h4>

      <p>Facilities admins have rights to manage all office sections.</p>

      <h4>Digital Signage Administrator</h4>

      <p>
        Digital signage admins have rights to manage slides and digital signage groups for the
        entire organization. Digital signage admins can manage endpoint branding. Office sections
        and devices can be viewed.
      </p>

      <h4>Digital Signage Contributor</h4>

      <p>
        Digital signage contributors have rights to manage slides and digital signage for the entire
        organization. Digital signage contributors can view all office sections.
      </p>

      <h2 id="toCommunifyMeet">Communify Meet</h2>

      <p>
        Communify Meet is a calendar hall display that integrates with Microsoft Exchange to display
        a room mailbox’s status, daily agenda, and uses the room’s calendar to inform new meeting
        creation. As such, room mailboxes need to be created in Exchange through, if they do not
        already exist.
      </p>

      <h3>Step-by-Step Room Resource Creation in Microsoft 365 </h3>

      <ol>
        <li>
          <p>
            Log into your Office web portal using the Administrator credentials for your company’s
            account.
          </p>{' '}
        </li>

        <li>
          <p>Select “Admin” from the list of available apps.</p>{' '}
        </li>

        <li>
          <p>
            Select the Equipment icon on the left, select “Rooms & equipment” then click the + to
            add a new meeting room
          </p>{' '}
        </li>

        <li>
          <p>
            If you don't see the Equipment icon in the menu on the left, you may be running
            Microsoft 365 for Small Business, or another variation. The set-up process is slightly
            different. See{' '}
            <a
              href="https://support.microsoft.com/en-us/help/10063/creating-and-managing-resource-mailboxes-in-office-365"
              target="_blank"
              rel="noopener noreferrer"
            >
              this guide
            </a>{' '}
            from Microsoft for more information.
          </p>{' '}
        </li>

        <li>
          <p>Fill in your room details.</p>{' '}
        </li>

        <li>
          <p>
            Select "Set scheduling options" to confirm that the room will automatically accept
            meeting invitations when it's available (this is the default).
          </p>{' '}
        </li>

        <li>
          <p>Repeat and create rooms for each of the bookable spaces in your office.</p>{' '}
        </li>

        <li>
          <p>Your rooms are now ready to be added to Communify.</p>
        </li>
      </ol>

      <h3>Rooms</h3>

      <p>
        Rooms are a physical representation of the room resources in your Microsoft 365 address
        book.
        <br />
        <br />
        To add a room, select the "Add Room" button from <strong>Offices {'>'} Rooms</strong> or
        navigate to a Building Details page and select the “Add Assignment“ button from a Building
        Details page.
        <br />
        <br />
        Use the form to enter the Exchange room resource’s email address (which you can get from the
        Microsoft 365 portal) and assign a location. Upon creating the room, visit the Room Detail
        page to edit properties and configuration.
      </p>

      <h3>Add multiple rooms</h3>

      <p>
        Multiple rooms can be added by visiting <strong>Offices {'>'} Rooms</strong> and selecting
        the “Add Multiple Rooms” button. Download the provided .csv file, complete the fields and
        save, and upload the completed .csv from the “Add Multiple Rooms” form.
        <br />
        <br />
        Adding multiple rooms can also be completed by following the same steps from a Building
        Details page.
      </p>

      <h3>Room Settings</h3>

      <p>
        Admins can add and edit room features and technologies by visiting{' '}
        <strong>Rooms {'>'} Room Settings</strong>. Configured features can be added to each room
        from the Room Details page.
      </p>

      <h3>Room Details</h3>

      <p>
        Each room’s configuration and properties can be viewed and modified from the Room Details
        page. <br />
        <br />
        Some of the room’s basic properties, including the capacity, room phone number, and device
        names, are synced from Exchange and may only be modified for tenants with a connected
        Service Account. <br />
        <br />
        Communify-powered settings and properties are used exclusively by Communify products, like
        the Communify App for Teams, to enable enhanced search, reservation, and booking rules.{' '}
        <br />
        <br />
        A room’s building and campus assignment, display image, floor, and room number can be
        updated from the Room Details page. Room features, configured from the Room Settings
        section, can also be added and removed. Administrators can view and modify group assignment.{' '}
        <br />
        <br />
        For tenants with Occupancy Settings enabled, the room’s actual and applied reservation
        capacity is displayed under the Occupancy Setting section the Room Details page. Capacity
        Management is applied at the campus level. In this section the room’s minimum booking number
        – the threshold of attendees required for the room to be available for a meeting – can also
        be modified.
      </p>

      <h2 id="toCommunifyGreet">Communify Greet</h2>

      <p>
        Communify Greet is a temperature gating kiosk that functions as an endpoint on the Power
        Platform Return to Work / School solution.
      </p>

      <h3>Kiosks</h3>

      <p>
        Kiosks are physical representations of the Greet endpoints installed at the entrances of
        buildings.
        <br />
        <br />
        To add a new kiosk, select the "Add Kiosk" button from <strong>
          Offices {'>'} Kiosks
        </strong>{' '}
        or select the “Add Assignment” button from a Building Detail page. Use the form to enter a
        name and assign a location. Upon creating the kiosk, visit the Kiosk Detail page to edit
        properties.
      </p>
      <span>
        Note: Greet Kiosks can only be added to Buildings previously linked with RTW facilities.
        Visit <a href="#toOffices_RTW_RTS">Link a Communify Building to an RTW / RTS Facility</a>{' '}
        for information about pairing Buildings to RTW facilities.
      </span>

      <p>
        Adding a kiosk can also be initiated by visiting a building detail page (
        <strong>Offices {'>'} Buildings</strong>) and selecting the “Add kiosk” button.
      </p>

      <h3>Add multiple kiosks</h3>

      <p>
        Multiple rooms can be added by visiting <strong>Offices {'>'} Kiosks</strong> and selecting
        the “Add Multiple Kiosks” button. Download the provided .csv file, complete the fields and
        save, and upload the completed .csv from the “Add Multiple Kiosks” form.
        <br />
        <br />
        Adding multiple kiosks can also be completed by following the same steps from a Building
        Details page.
      </p>

      <h3>Kiosk Settings</h3>

      <p>
        To modify temperature gating configuration, navigating to Kiosks {'>'} Kiosk Settings.
        Settings include:
      </p>

      <ol>
        <li>
          <p>Temperature capture mode (F or C)</p>
        </li>

        <li>
          <p>Temperature threshold range</p>
        </li>

        <li>
          <p>Campus-based temperature thresholds</p>
        </li>

        <li>
          <p>Kiosk messaging</p>
        </li>

        <li>
          <p>Greet Health Check configuration</p>
        </li>
      </ol>

      <h2 id="toCommunifySeat">Communify Seat</h2>

      <p>
        Communify Seat is a workspace area display that can be integrated with Microsoft Exchange or
        Power Platform’s Return to the Work / School solutions to display a workspace’s status,
        capacity, and create new seat reservations. As such, workspaces need to be created in
        Exchange or Power Platform if they do not already exist.{' '}
      </p>

      <h3>Step-by-Step Workspace Resource Creation in Microsoft 365</h3>

      <p>
        Currently, the only way to set up a workspace in Exchange Online is via Exchange PowerShell.
        The following directions assume you are using standard M365 or M365 GCC and are the minimal
        instructions for setting up a workspace to work with Communify. [See complete instructions
        for{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.microsoft.com/en-us/powershell/exchange/connect-to-exchange-online-powershell?view=exchange-ps"
        >
          Exchange PowerShell
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://techcommunity.microsoft.com/t5/exchange-team-blog/book-a-workspace-in-outlook/ba-p/1524560"
        >
          Creating a Workspace
        </a>
        ]
      </p>

      <ul style={{ listStyleType: 'auto' }}>
        <li>
          Open PowerShell and load the Exchange Online module with
          <br />{' '}
          <p style={{ fontFamily: 'Fira Code', marginLeft: 0, fontWeight: 300 }}>
            Import-Module ExchangeOnlineManagement
          </p>{' '}
        </li>

        <li>
          Sign in to Exchange with Connect-ExchangeOnline -UserPrincipalName [your administrator
          UPN/email address]
          <br />
          <br />A Microsoft login window will appear. Once you authenticate you can return to
          PowerShell.
        </li>
        <li>
          Create a workspace resource with New-Mailbox -Room [alias] | Set-Mailbox -Type Workspace
          <br />
          <br />
          <strong>You must wait </strong>approximately 24 hours before continuing (or you will get
          error messages that the workspace does not exist), so it is best to create all your
          workspace resources in batch now/as early as possible to minimize waiting.
        </li>
        <li>
          Set the capacity of the workspace with Set-Place -Identity "[alias]" -Capacity [number]
        </li>
        <li>
          Tell Exchange Online to enforce the capacity restriction with Set-CalendarProcessing
          [alias] -EnforceCapacity $True 
        </li>
      </ul>

      <p>
        Your workspace is now ready to be added to Communify. Note that other optional settings for
        Exchange workspace can be set; see the Microsoft documentation links above for further
        details.
      </p>

      <h3>Step-by-Step Workspace Resource Creation in RTW/RTS</h3>

      <p>Workspaces in Communify equate to areas in a facility in RTW/RTS.</p>

      <ul style={{ listStyleType: 'auto' }}>
        <ol>
          Open the <strong>Facility Safety Management</strong> Power App
        </ol>
        <ol>
          On the left-hand menu, select <strong>Facilities</strong> and then the facility you want
          to create an area in, or create a new facility.
        </ol>
        <ol>
          From the facility detail screen, select the <strong>Occupancy</strong> tab at the top,
          under the reopening progress bar.
        </ol>
        <ol>
          Areas must be associated with floors, so use the <strong>New Floor</strong> button to
          create a floor if necessary. Note that you must input the floor name and floor index (this
          particular floor’s number in a numerical ordering of floors).
        </ol>
        <ol>
          Back on the facility detail screen, us the <strong>New Area</strong> button to create a
          new area. You must supply the area name and floor, and while the capacity is not required
          by RTW, you will need to enter it for use with Communify.
        </ol>
      </ul>

      <h3>Workspaces</h3>

      <p>
        Workspaces are entities in Communify that represent a capacity-based group of seats.
        Workspaces can be paired to Seat endpoints installed centrally to physical hotdesking and
        hoteling areas in a facility.
        <br />
        <br />
        To add a new Workspace, select the "Add Workspace" button from{' '}
        <strong>Offices {'>'} Workspaces</strong> or select the “Add Assignment” button from a
        Building Detail page.
      </p>
      <p>
        Use the form to enter the Exchange workspace resource’s email address, or the RTW/RTS area
        name, and assign a location. Upon creating the workspace, visit the Workspace Detail page to
        edit properties and configurations.
      </p>
      <span className="span-margin-bt-thiner-than-usual">
        Note: Seat endpoints on Power PLatform`s Return to Work/ School solution must be added to
        buildings previously linked with RTW facilities. Visit{' '}
        <a href="#toOffices_RTW/RTS">Link a Communify Building to an RTW / RTS Facility</a> for
        information about pairing Buildings to RTW facilities.
      </span>

      <h3>Add multiple workspaces</h3>

      <p>
        Multiple workspaces can be added by visiting <strong>Offices {'>'} Workspaces</strong> and
        selecting the “Add Multiple Workspaces” button. Download the provided .csv file, complete
        the fields and save, and upload the completed .csv from the “Add Multiple Workspaces” form.
        <br />
        <br />
        Adding multiple workspaces can also be completed by following the same steps from a Building
        Details page.
      </p>

      <h3>Workspace Settings</h3>

      <p>
        Admins can add and edit workspace types and environments by visiting{' '}
        <strong>Workspaces {'>'} Workspace Settings</strong>. Configured settings can be applied to
        each workspace from the Workspace Details page.
      </p>

      <h3>Workspace Details</h3>

      <p>
        Each workspace’s configuration and properties can be viewed and modified from the Room
        Details page.
      </p>

      <p>
        Some of the room’s basic properties, including the capacity, workspace phone number, and
        device names, are synced from Exchange and may not be edited from Communify.
      </p>

      <p>
        Communify-powered settings and properties are used exclusively by Communify products, like
        the Communify App for Teams, to enable enhanced search, reservation, and booking rules.
      </p>

      <p>
        A workspace’s building and campus assignment, display image, floor, and room number can be
        updated from the Workspace Details page. Workspace configuration such as the type and
        environment can also be applied. Administrators can view and modify group assignment.
      </p>

      <p>
        For tenants with Occupancy Settings enabled, the workspace’s actual and applied reservation
        capacity is displayed under the Occupancy Setting section the Workspace Details page.
        Capacity Management is applied at the campus level.
      </p>

      <h2 id="toCommunifyApp">Communify App</h2>

      <p>
        The Communify App is a self-service reservation management tool for employees that serves as
        a complement to activated Communify endpoints. Rooms and workspaces paired to activated Meet
        and Seat devices can be reserved with Communify-powered features like advanced search,
        attendee status, room release, and occupancy rules. The Communify App is deployed
        exclusively through the Microsoft Teams client and is available for desktop and mobile
        users.{' '}
      </p>

      <h3>Adding the Communify App to Microsoft Teams </h3>

      <p>Requirements:</p>

      <ul>
        <li>A Communify subscription and Communify-enabled endpoints.</li>

        <li>Microsoft 365 integration.</li>
      </ul>

      <p>
        There are several possible ways to deploy the Communify App depending on the Teams settings
        for your organization. This is visible by an M365 Administrator in the Teams apps page in
        the Microsoft Teams admin center, accessible at{' '}
        <a href="https://admin.teams.microsoft.com" target="_blank" rel="noopener noreferrer">
          https://admin.teams.microsoft.com
        </a>
        .
      </p>

      <span>
        Note: Please ensure that third-party apps are enabled for your organization. If third-party
        apps are disabled, you will be prompted to enable them as you complete the steps below.
      </span>

      <h3>Step-by-step adding the Communify App to Microsoft Teams for all users </h3>

      <ol id="ol-different-element">
        <li>
          <p>
            Navigate to the Setup policies tab of the Microsoft Teams Admin Center to add
            the Communify App for Teams. Remember that you must do this for your organization’s
            various setup policies that you want affected.
          </p>
        </li>

        <li>
          <p>Choose an option:</p>
        </li>

        <ol type="a">
          <li>
            <p>
              Simply installing the app for your users, which they can add and pin to their
              navigation bar independently from the Teams Marketplace.
            </p>
          </li>
          <li>
            <p>Pin the app to all users’ navigation bar.</p>
          </li>
        </ol>
        <li>
          <p>Press Save when complete.</p>
        </li>
      </ol>

      <span>
        Note: Any of these changes may take some time to propagate to your Teams users. Signing out
        and back in will help load new policies faster, but it may still take a while for these
        policies to roll out).
      </span>

      <p>
        For organizations restricting apps, The Communify App can also be added to the list of
        “Allowed Apps” by a Teams administrator and installed by users from the Teams Marketplace.
        Employees of organizations not restricting apps can install and pin the Communify App
        without further assistance.
      </p>

      <h3>Communify App Settings </h3>

      <p>
        Room Release provides for rooms to be removed from meetings when no on-site guests confirm
        their check-in for a meeting. From the <strong>Settings</strong> section, Administrators may
        enable or disable Room Release, and configure check-in windows for users of the Communify
        app.
      </p>
      <p>
        Check-in and Attendee Status are automatically enabled when Room Release is enabled. For
        organizations with Room Release not enabled, Check-In and Attendee Status can be optionally
        enabled.
      </p>

      <h2>Contact Information</h2>

      <h3>Support</h3>

      <p>
        <a href="mailto:support@communifyapp.com">support@communifyapp.com</a>
      </p>

      <h3>Sales Inquiries</h3>

      <p>
        <a href="mailto:sales@communifyapp.com">sales@communifyapp.com</a>
      </p>
    </div>
  );
};

export default SupportMainNew;
