import React from 'react';
import { Helmet } from 'react-helmet';


const PDF = props => {
      return (
            <div>
                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>{props.metaTitle}</title>
                        <meta name="description" content={props.metaDescription} />
                  </Helmet>
                  <object data={props.filePath} type="application/pdf" style={{ width: '100%', height: '100vh' }} aria-label='support guide'></object>
            </div>
      );
}

export default PDF;
