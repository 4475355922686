import React from "react";
import Footer from "../../Includes/Footer";
import Header from "../../Includes/Header";
import EULAcontent from "./Components/EULAcontent";
import "./EULA.css";

const EULA = () => {
  return (
    <>
      <div className="App wrapper-app app-eula">
        <Header />
        <div className="wrapper-guide">
          <div className="container-custom container-EULA">
            <EULAcontent />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EULA;
