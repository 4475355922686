import React, { useEffect } from 'react';

import scrollToElem from '../../Libs/smoothScrollPolyfill';
import { useHistory } from 'react-router-dom';
import Menu from '../../UI/Navigation/Menu';

const Navigation = () => {
      const history = useHistory();
      useEffect(() => {
            if(history.location.state){
                  const prevPath = history.location.state.from;
                  const elemId = history.location.state.elemId;
                  const prevHash = history.location.state.manualHash;
                  if(prevPath !== '/'){
                        if(prevHash){
                              window.location.hash = prevHash;
                        }
                        scrollToElem(elemId)
                  }
            }
      }, [history])
      return (
            <div className='row custom-nav-wrapper'>
                  <div className="col">
                        <Menu />
                  </div>
            </div>
      );
}

export default Navigation;
