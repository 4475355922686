import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Custom components
import App from './Components/App'
import {store, persistor} from './Store';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <App/>
                </Router>
        </PersistGate>
    </Provider>
, document.getElementById('root'));