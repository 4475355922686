import React from "react";
import Header from "../../Includes/Header";
import Footer from "../../Includes/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions | Communify</title>
        <meta
          name="description"
          content="Reduce workplace friction to get business back to work"
        />
      </Helmet>
      <div className="terms-wrapper">
        <Header />
        <div className="container">
          <div className="terms-heading cond-heading d-flex flex-column align-items-center">
            <h1 className="terms-title">
              SaaS Subscription Terms and Conditions
            </h1>
            <p className="last-modified">Last Modified: November 27, 2020</p>
            <section className="introduction">
              <h2 className="underlined">
                Acceptance of the Terms and Conditions{" "}
              </h2>
              <div className="introduction-content">
                <p>
                  THE FOLLOWING TERMS AND CONDITIONS, TOGETHER WITH ANY
                  DOCUMENTS OR POLICIES THEY EXPRESSLY INCORPORATE BY REFERENCE
                  (COLLECTIVELY, “TERMS AND CONDITIONS”), GOVERN YOUR
                  SUBSCRIPTION AND ACCESS TO AND USE OF ONE OR MORE OF THE
                  TEKNIKOS, INC. (<span className="bold">“TEKNIKOS,”</span>{" "}
                  <span className="bold">“WE,”</span> OR{" "}
                  <span className="bold">“US”</span>) APPLICATIONS INCLUDED IN
                  THE “COMMUNIFY” SUITE OF SOFTWARE APPLICATIONS, A FULL LISTING
                  OF WHICH IS AVAILABLE AT WWW.COMMUNIFYAPP.COM, INCLUDING ANY
                  CONTENT, FUNCTIONALITY, AND SERVICES OFFERED ON OR THROUGH
                  SUCH APPLICATION(S) OR THE CORRESPONDING ADMINISTRATION PORTAL
                  (COLLECTIVELY THE <span className="bold">“APPLICATION”</span>
                  ).
                </p>
              </div>
            </section>
            <section className="inner-section">
              <p>
                <span className="bold">
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE YOU
                  ACTIVATE YOUR SUBSCRIPTION OR START TO USE THE APPLICATION. BY
                  (A) USING THE APPLICATION, OR (B) CLICKING TO ACCEPT OR AGREE
                  TO THE TERMS AND CONDITIONS WHEN THIS OPTION IS MADE AVAILABLE
                  TO YOU AT THE TIME OF ACTIVATING YOUR SUBSCRIPTION, YOU ACCEPT
                  AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS AND CONDITIONS,
                  OUR END USER LICENSE AGREEMENT, FOUND AT{" "}
                  <Link
                    style={{ fontWeight: "bold" }}
                    target="_blank"
                    to="/EULA"
                  >
                    COMMUNIFYAPP.COM/EULA
                  </Link>
                  , OUR PRIVACY POLICY, FOUND AT{" "}
                  <Link
                    target="_blank"
                    style={{ fontWeight: "bold" }}
                    to="/privacy"
                  >
                    COMMUNIFYAPP.COM/PRIVACY
                  </Link>
                  , AND OUR DATA PROCESSING AGREEMENT (IF APPLICABLE), FOUND AT{" "}
                  <Link
                    target="_blank"
                    style={{ fontWeight: "bold" }}
                    to="/data-processing-agreement"
                  >
                    COMMUNIFYAPP.COM/DATA-PROCESSING-AGREEMENT
                  </Link>
                  , WHICH ARE EACH INCORPORATED HEREIN BY REFERENCE.
                </span>
              </p>
            </section>
            <section className="inner-section">
              <p>
                The term “you” as used herein, is in reference to the individual
                accepting these Terms and Conditions, whether by using the
                Application or by to accept or agree to these Terms and
                Conditions when this option is made available to you at the time
                of activating your subscription. If the individual accepting
                these Terms and Conditions is accepting on behalf of a company
                or other legal entity at the time of activating a subscription,
                such individual represents that they have the authority to bind
                such entity and its affiliates to these Terms and Conditions, in
                which case the term “you” shall refer to such entity and its
                affiliates, including but not limited to its employees. If the
                individual accepting this agreement does not have such
                authority, is not of legal age to form a binding contract with
                Teknikos, or does not agree with these Terms and Conditions,
                such individual may not subscribe for the services provided
                through the Application, activate any such subscription, use the
                Application or allow its affiliates, including but not limited
                to its employees, to use the Application.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">
                Changes to the Terms and Conditions
              </h2>
              <p>
                We may revise and update these Terms and Conditions from time to
                time in our sole discretion. The current version of these Terms
                and Conditions may be found at{" "}
                <Link target="_blank" to="/terms-and-conditions">
                  communifyapp.com/terms-and-conditions.
                </Link>{" "}
                All changes are effective immediately when we post them, and
                apply to all subscribers to the Application thereafter. However,
                any changes to the dispute resolution provisions set out in the
                Governing Law and Jurisdiction provision herein will not apply
                to any disputes for which the parties have actual notice on or
                before the date the change is posted. Your continued use of the
                Application following the posting of revised Terms and
                Conditions means that you accept and agree to the changes. You
                are expected to check the Terms and Conditions page frequently
                so you are aware of any changes, as they are binding on you.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">
                Accessing the Application and Account Security
              </h2>
              <p>
                We reserve the right to withdraw or amend the Application, and
                any service or material we provide on the Application, in our
                sole discretion without notice. We will not be liable if for any
                reason all or any part of the Application is unavailable at any
                time or for any period. From time to time, we may restrict
                access to some parts of the Application, or the entire
                Application, to users.
              </p>
              <p>You are responsible for both:</p>
              <ul>
                <li>
                  Making all arrangements necessary for you to have access to
                  the Application.
                </li>
                <li>
                  Ensuring that all persons who access the Application through
                  your subscription are aware of these Terms and Conditions and
                  comply with them.
                </li>
              </ul>
              <p>
                To activate your subscription for the Application or to use some
                of the services it offers, you may be asked to provide certain
                registration details or other information. It is a condition of
                your use of the Application that all the information you provide
                on the Application is correct, current, and complete. You agree
                that all information you provide to register with this
                Application or otherwise, including, but not limited to, through
                the use of any interactive or user-generated content features on
                the Application, is governed by our{" "}
                <Link target="_blank" to="/privacy">
                  Privacy Policy
                </Link>
                , and you consent to all actions we take with respect to your
                information consistent with our Privacy Policy.
              </p>
              <p>
                If you choose, or are provided with, a user name, password, or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential, and
                you must not disclose it to any other person or entity. You also
                acknowledge that your account is personal to you and agree not
                to provide any other person with access to the Application or
                portions of it using your user name, password, or other security
                information. You agree to notify us immediately of any
                unauthorized access to or use of your user name or password or
                any other breach of security. You also agree to ensure that you
                exit from your account at the end of each session. You should
                use particular caution when accessing your account from a public
                or shared computer so that others are not able to view or record
                your password or other personal information.
              </p>
              <p>
                We have the right to disable any user name, password, or other
                identifier, whether chosen by you or provided by us, at any time
                in our sole discretion for any or no reason, including if, in
                our opinion, you have violated any provision of these Terms and
                Conditions.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Intellectual Property Rights</h2>
              <p>
                The Application and its entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof) are owned by
                Teknikos, its licensors, or other providers of such material and
                are protected by United States and international copyright,
                trademark, patent, trade secret, and other intellectual property
                or proprietary rights laws.
              </p>
              <p>You must not:</p>
              <ul>
                <li>Modify the Application.</li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary
                  rights notices from copies of materials from the Application.
                </li>
              </ul>
              <p>
                If you print, copy, modify, download, or otherwise use or
                provide any other person with access to any part of the
                Application in breach of the Terms and Conditions, without prior
                written consent by Teknikos, your subscription and your right to
                use the Application will stop immediately and you must delete
                the Application. No right, title, or interest in or to the
                Application or any content on the Application is transferred to
                you, and all rights not expressly granted are reserved by
                Teknikos. Any use of the Application not expressly permitted by
                these Terms and Conditions is a breach of these Terms and
                Conditions and may violate copyright, trademark, and other laws.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Trademarks</h2>
              <p>
                Teknikos’ name, the names of all Communify Applications,
                Teknikos’ logo, and all related names, logos, product and
                service names, designs, and slogans are trademarks of or its
                affiliates or licensors. You must not use such marks without the
                prior written permission of Teknikos. All other names, logos,
                product and service names, designs, and slogans on the
                Application are the trademarks of their respective owners.
              </p>
              <p>
                By agreeing to these Terms and Conditions, you grant Teknikos
                the right to use your name, logo, trade name, trademark, service
                mark for Teknikos’ publicity and marketing efforts, including on
                Teknikos’ client list and on its website, without first
                obtaining your consent. Teknikos agrees to limit such use to a
                manner that factually represents your client relationship with
                Teknikos.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Prohibited Uses</h2>
              <p>
                You may use the Application only for lawful purposes and in
                accordance with these Terms and Conditions. You agree not to use
                the Application:
              </p>
              <ul>
                <li>
                  {" "}
                  In any way that violates any applicable federal, state, local,
                  or international law or regulation (including, without
                  limitation, any laws regarding the export of data or software
                  to and from the US or other countries).{" "}
                </li>
                <li>
                  For the purpose of exploiting, harming, or attempting to
                  exploit or harm minors in any way by exposing them to
                  inappropriate content, asking for personally identifiable
                  information, or otherwise.{" "}
                </li>
                <li>
                  To impersonate or attempt to impersonate Teknikos, a Teknikos
                  employee, another user, or any other person or entity
                  (including, without limitation, by using email addresses
                  associated with any of the foregoing).{" "}
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of the Application, or which, as
                  determined by us, may harm Teknikos or users of the
                  Application, or expose them to liability.
                </li>
              </ul>
              <p>Additionally, you agree not to: </p>
              <ul>
                <li>
                  Use the Application in any manner that could disable,
                  overburden, damage, or impair the Application or interfere
                  with any other party’s use of the Application, including their
                  ability to engage in real time activities through the
                  Application.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or
                  means to access the Application for any purpose, including
                  monitoring or copying any of the material on the Application.{" "}
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material
                  on the Application, or for any other purpose not expressly
                  authorized in these Terms and Conditions, without our prior
                  written consent.{" "}
                </li>
                <li>
                  Use any device, software, or routine that interferes with the
                  proper working of the Application.
                </li>
                <li>
                  Introduce any viruses, Trojan horses, worms, logic bombs, or
                  other material that is malicious or technologically harmful.{" "}
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of the Application, the server on
                  which the Application is stored, or any server, computer, or
                  database connected to the Application.{" "}
                </li>
                <li>
                  Attack the Application via a denial-of-service attack or a
                  distributed denial-of-service attack.{" "}
                </li>
                <li>
                  Otherwise attempt to interfere with the proper working of the
                  Application.{" "}
                </li>
              </ul>
            </section>
            <section className="information">
              <h2 className="underlined">User Contributions</h2>
              <p>
                The Application may contain interactive or user-generated
                content features, including, without limitation, digital signage
                (collectively,{" "}
                <span className="bold">“Interactive Services”</span>) that allow
                users to post, submit, publish, upload, generate, display, or
                transmit (hereinafter, <span className="bold">“post”</span>)
                content or materials, whether or not accessible to other users
                or other persons (collectively,{" "}
                <span className="bold">“User Contributions”</span>) on or
                through the Application.
              </p>
              <p>
                Any User Contribution you post on the Application will be
                considered non-confidential and non-proprietary. By providing
                any User Contribution on the Application, you grant us and our
                affiliates and service providers, and each of their and our
                respective licensees, successors, and assigns the right to use,
                reproduce, modify, perform, display, distribute, and otherwise
                disclose to third parties any such material for any purpose, in
                accordance with our Privacy Policy.
              </p>
              <p>
                You represent and warrant that all of your User Contributions do
                and will comply with these Terms and Conditions.
              </p>
              <p>
                You understand and acknowledge that you are responsible for any
                User Contributions you submit or contribute, and you, not
                Teknikos, have full responsibility for such content, including
                its legality, reliability, accuracy, and appropriateness.
              </p>
              <p>
                WE ARE NOT RESPONSIBLE OR LIABLE TO YOU OR ANY THIRD PARTY FOR
                THE CONTENT OR ACCURACY OF ANY USER CONTRIBUTIONS POSTED BY YOU
                OR ANY OTHER USER OF THE APPLICATION.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">
                Monitoring and Enforcement; Termination
              </h2>
              <p>
                Your right to use the Application commences on the date you
                first activate your subscription and continues until such
                subscription has expired or has been terminated.
              </p>
              <p>We have the right to:</p>
              <ul>
                <li>
                  Take any action with respect to any User Contribution that we
                  deem necessary or appropriate in our sole discretion,
                  including if we believe that such User Contribution violates
                  the Terms and Conditions, infringes any intellectual property
                  right or other right of any person or entity, threatens the
                  personal safety of users of the Application or the public, or
                  could create liability for Teknikos.
                </li>
                <li>
                  Disclose your identity or other information about you to any
                  third party who claims that material posted by you violates
                  their rights, including their intellectual property rights or
                  their right to privacy.
                </li>
                <li>
                  Take appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of the Application.{" "}
                </li>
                <li>
                  Terminate or suspend your subscription or your access to all
                  or part of the Application for any or no reason, including
                  without limitation, any violation of these Terms and
                  Conditions.
                </li>
              </ul>
              <p>
                Without limiting the foregoing, we have the right to cooperate
                fully with any law enforcement authorities or court order
                requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or through the
                Application. YOU WAIVE AND HOLD HARMLESS TEKNIKOS AND ITS
                AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
                DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
                SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
              </p>
              <p>
                However, we do not undertake to monitor, review or screen
                material before it is posted on or through the Application, and
                cannot ensure prompt removal of objectionable material after it
                has been posted. Accordingly, we assume no liability for any
                action or inaction regarding transmissions, communications, or
                content provided by any user or third party. We have no
                liability or responsibility to anyone for performance or
                nonperformance of the activities described in this section.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Content Standards</h2>
              <p>
                These content standards apply to any and all uses of the
                Application. User Contributions must in their entirety comply
                with all applicable federal, state, local, and international
                laws and regulations. Without limiting the foregoing, User
                Contributions must not:
              </p>
              <ul>
                <li>
                  Contain any material that is defamatory, obscene, indecent,
                  abusive, offensive, harassing, violent, hateful, inflammatory,
                  or otherwise objectionable.{" "}
                </li>
                <li>
                  Promote sexually explicit or pornographic material, violence,
                  or discrimination based on race, sex, religion, nationality,
                  disability, sexual orientation, or age.
                </li>
                <li>
                  Infringe any patent, trademark, trade secret, copyright, or
                  other intellectual property or other rights of any other
                  person.
                </li>
                <li>
                  Violate the legal rights (including the rights of publicity
                  and privacy) of others or contain any material that could give
                  rise to any civil or criminal liability under applicable laws
                  or regulations or that otherwise may be in conflict with these{" "}
                  <Link target="_blank" to="/terms-and-conditions">
                    Terms and Conditions
                  </Link>{" "}
                  and our{" "}
                  <Link target="_blank" to="/privacy">
                    Privacy Policy
                  </Link>{" "}
                </li>
                <li>
                  Promote any illegal activity, or advocate, promote, or assist
                  any unlawful act.
                </li>
                <li>
                  Cause annoyance, inconvenience, or needless anxiety or be
                  likely to upset, embarrass, alarm, or annoy any other person.
                </li>
                <li>
                  Give the impression that they emanate from or are endorsed by
                  us or any other person or entity, if this is not the case.
                </li>
              </ul>
            </section>
            <section className="information">
              <h2 className="underlined">Reliance on Information Posted</h2>
              <p>
                The information presented on or through the Application is made
                available solely for general information purposes. We do not
                warrant the accuracy, completeness, or usefulness of this
                information. Any reliance you place on such information is
                strictly at your own risk. We disclaim all liability and
                responsibility arising from any reliance placed on such
                materials by you or any other visitor to the Application, or by
                anyone who may be informed of any of its contents.
              </p>
              <p>
                This Application includes content provided by third parties,
                including information provided by other users and automatically
                integrated into the Application from Microsoft Office 365. We
                are not responsible, or liable to you or any third party, for
                the content or accuracy of any information provided by any third
                parties.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Links from the Application</h2>
              <p>
                If the Application contains links to websites and resources
                provided by third parties, these links are provided for your
                convenience only. We have no control over the contents of those
                sites or resources, and accept no responsibility for them or for
                any loss or damage that may arise from your use of them. If you
                decide to access any of the third-party websites linked to the
                Application, you do so entirely at your own risk and subject to
                the terms and conditions of use for such websites.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Geographic Restrictions</h2>
              <p>
                The owner of the Application is based in the Commonwealth of
                Pennsylvania in the United States. If you access the Application
                from outside the United States, you do so on your own initiative
                and are responsible for compliance with local laws.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Disclaimer of Warranties</h2>
              <p>
                You are responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to the Application for any
                reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED
                BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
                MATERIAL DUE TO YOUR USE OF THE APPLICATION OR ANY SERVICES OR
                ITEMS OBTAINED THROUGH THE APPLICATION OR TO YOUR DOWNLOADING OF
                ANY MATERIAL POSTED ON IT, OR ON ANY APPLICATION LINKED TO IT.
                YOUR USE OF THE APPLICATION, ITS CONTENT, AND ANY SERVICES OR
                ITEMS OBTAINED THROUGH THE APPLICATION IS AT YOUR OWN RISK. THE
                APPLICATION, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
                THROUGH THE APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS
                AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                EXPRESS OR IMPLIED. NEITHER TEKNIKOS NOR ANY PERSON ASSOCIATED
                WITH TEKNIKOS MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
                OR AVAILABILITY OF THE APPLICATION. WITHOUT LIMITING THE
                FOREGOING, NEITHER TEKNIKOS NOR ANYONE ASSOCIATED WITH TEKNIKOS
                REPRESENTS OR WARRANTS THAT THE APPLICATION, ITS CONTENT, OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION WILL BE
                ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
                WILL BE CORRECTED, THAT OUR APPLICATION OR THE SERVER THAT MAKES
                IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
                THAT THE APPLICATION OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE APPLICATION WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                TO THE FULLEST EXTENT PROVIDED BY LAW, TEKNIKOS HEREBY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
                WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
                THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Limitation on Liability</h2>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL
                TEKNIKOS, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS,
                EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES
                OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
                CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APPLICATION,
                ANY WEBSITES OR OTHER APPLICATIONS LINKED TO IT, ANY CONTENT ON
                THE APPLICATION, SUCH WEBSITES OR OTHER APPLICATIONS, INCLUDING
                ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY,
                PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
                PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE,
                LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
                (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
                IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT
                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Indemnification</h2>
              <p>
                You agree to defend, indemnify, and hold harmless Teknikos, its
                affiliates, licensors, and service providers, and its and their
                respective officers, directors, employees, contractors, agents,
                licensors, suppliers, successors, and assigns from and against
                any claims, liabilities, damages, judgments, awards, losses,
                costs, expenses, or fees (including reasonable attorneys’ fees)
                arising out of or relating to your violation of these Terms and
                Conditions or your use of the Application, including, but not
                limited to, your User Contributions, any use of the
                Application’s content, services, and products other than as
                expressly authorized in these Terms and Conditions, or your use
                of any information obtained from the Application.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Governing Law and Jurisdiction</h2>
              <p>
                All matters relating to the Application and these Terms and
                Conditions, and any dispute or claim arising therefrom or
                related thereto (in each case, including non-contractual
                disputes or claims), shall be governed by and construed in
                accordance with the internal laws of the Commonwealth of
                Pennsylvania without giving effect to any choice or conflict of
                law provision or rule (whether of the Commonwealth of
                Pennsylvania or any other jurisdiction).
              </p>
              <p>
                Any legal suit, action, or proceeding arising out of, or related
                to, these Terms and Conditions or the Application shall be
                instituted exclusively in the federal courts of the United
                States or the courts of the Commonwealth of Pennsylvania, in
                each case located in the City of Philadelphia and County of
                Philadelphia, although we retain the right to bring any suit,
                action, or proceeding against you for breach of these Terms and
                Conditions in your country of residence or any other relevant
                country. You waive any and all objections to the exercise of
                jurisdiction over you by such courts and to venue in such
                courts.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Arbitration</h2>
              <p>
                At Company’s sole discretion, it may require you to submit any
                disputes arising from these Terms and Conditions or use of the
                Application, including disputes arising from or concerning their
                interpretation, violation, invalidity, non-performance, or
                termination, to final and binding arbitration under the Rules of
                Arbitration of the American Arbitration Association applying
                Pennsylvania law.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Limitation on Time to File Claims</h2>
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS AND CONDITIONS OR THE APPLICATION MUST
                BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
                BARRED.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Waiver and Severability</h2>
              <p>
                No waiver by Teknikos of any term or condition set out in these
                Terms and Conditions shall be deemed a further or continuing
                waiver of such term or condition or a waiver of any other term
                or condition, and any failure of Teknikos to assert a right or
                provision under these Terms and Conditions shall not constitute
                a waiver of such right or provision.
              </p>
              <p>
                If any provision of these Terms and Conditions is held by a
                court or other tribunal of competent jurisdiction to be invalid,
                illegal, or unenforceable for any reason, such provision shall
                be eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms and Conditions will continue
                in full force and effect.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Entire Agreement</h2>
              <p>
                The Terms and Conditions, our Privacy Policy, End User License
                Agreement, and all other policies and disclaimers incorporated
                by reference therein constitute the sole and entire agreement
                between you and Teknikos regarding the Application and supersede
                all prior and contemporaneous understandings, agreements,
                representations, and warranties, both written and oral,
                regarding the Application. To the extent any terms or provisions
                of the foregoing documents may be deemed inconsistent, these
                Terms and Conditions shall govern; provided, however, that if
                such inconsistency is deemed by Teknikos, in its sole
                discretion, to be related to privacy, the Privacy Policy shall
                govern.
              </p>
            </section>
            <section className="information">
              <h2 className="underlined">Your Comments and Concerns</h2>
              <p>
                All feedback, comments, requests for technical support, and
                other communications relating to the Application should be
                directed to:{" "}
                <a href="mailto:communifysupport@teknikos.com">
                  communifysupport@teknikos.com.
                </a>
              </p>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
