import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from './Routing/SubRouting';
import { routesConfig } from '../Configs/Routes/routesConfig';

import './Assets/css/styles.css';

const App = () => {
      return (
            <React.Fragment>
                  <Switch>
                        {routesConfig.map((route, i) => (
                              <RouteWithSubRoutes key={i} {...route} />
                        ))}
                  </Switch>
            </React.Fragment>
      );
}

export default App;