import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { menuItems } from '../../../Configs/Menu/MenuItems';
import { toggleMobileMenu } from '../../Utils';

const MobileMenu = () => {
      const history = useHistory();
      return (
            <div className="overlay-content d-flex flex-column">
                  {menuItems.map((elem, index) => {
                        const link = (history.location.pathname === '/' ? elem.hash : { pathname: "/", state: { manualHash: elem.hash} });
                        return (
                              typeof link === 'object' ? 
                                    <Link key={index} onClick={e => toggleMobileMenu('close')} to={link}>{elem.name}</Link>
                              : <a key={index} onClick={e => toggleMobileMenu('close')} href={link} className='nav-link'>{elem.name}</a>
                        )
                  })}
            </div>
      );
}

export default MobileMenu;
