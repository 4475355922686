import * as actionTypes from '../ActionTypes';

const initialState = {
      successMessage: null,
      errorMessage: null,
      dimensions: {
            width: window.innerWidth,
            height: window.innerHeight
      }
};

const messageLogReducer = (state = initialState, action) => {
      switch (action.type) {
            case actionTypes.SET_SUCCESS_MESSAGE:
                  return {
                        ...state,
                        successMessage: action.message
                  }
            case actionTypes.SET_ERROR_MESSAGE:
                  return {
                        ...state,
                        errorMessage: action.message
                  }
            case actionTypes.SET_DIMENSIONS:
                  return {
                        ...state,
                        dimensions: action.dimensions
                  }
            default:
                  return state;
      }
}

export default messageLogReducer;