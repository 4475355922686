import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './Reducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
      key: 'root',
      storage,
      blacklist: ['dimensions']
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
      persistedReducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // development purposes
);

let persistor = persistStore(store);
export { store, persistor };