import React from "react";

import Main from "../../Components/Pages/Home/Main";
import Terms from "../../Components/Pages/Terms";
import Privacy from "../../Components/Pages/Privacy";
import PDF from "../../Components/Pages/PDf";

import PDFSupportguide from "../../Components/Assets/Files/SupportGuide.pdf";
import PDFSpaceScheduling from "../../Components/Assets/Files/SpaceScheduling.pdf";
// import SupportGuide from '../../Components/Pages/SupportGuide';
import NotFound from "../../Components/Pages/NotFound";
import DataProcessing from "../../Components/Pages/DataProcessing/DataProcessing";
import RtwDocs from "../../Components/Pages/RTWdocs/RtwDocs";
import Contact from "../../Components/Pages/Contact/Contact";
import SupportGuideNew from "../../Components/Pages/SupportGuide/SupportGuideNew";
import EULA from "../../Components/Pages/EULA/EULA";

const routesConfig = [
  {
    path: "/",
    exact: true,
    component: Main,
  },
  {
    path: "/terms-and-conditions",
    component: Terms,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/data-processing-agreement",
    component: DataProcessing,
  },
  {
    path: "/documents/supportguide.pdf",
    component: () => (
      <PDF
        filePath={PDFSupportguide}
        metaTitle="Meet Support Guide"
        metaDescription={PDFSupportguide}
      />
    ),
  },
  {
    path: "/documents/flyer.pdf",
    component: () => (
      <PDF
        filePath={PDFSpaceScheduling}
        metaTitle="Meeting Space Scheduling Experience"
        metaDescription={PDFSpaceScheduling}
      />
    ),
  },
  // {
  //       path: '/support-guide',
  //       component: SupportGuide
  // },
  {
    path: "/support-guide",
    component: SupportGuideNew,
  },
  {
    path: "/EULA",
    component: EULA,
  },
  {
    path: "/GreetRTW-Setup",
    component: RtwDocs,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export { routesConfig };
