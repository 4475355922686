import React from "react";

import increaseRoom from "../../Assets/Images/img-products-meet.png";
import easyReservation from "../../Assets/Images/img-products-greet.png";
import seatImage from "../../Assets/Images/seat/img-products-seat@3x.png";
import meetNow from "../../Assets/Images/img-products-teams.png";
import digitalSignage from "../../Assets/Images/img-features-digital-signage.png";
import { Link } from "react-router-dom";
import scrollToElem from "../../Libs/smoothScrollPolyfill";

const Features = () => {
  return (
    <div id="products" className="container-fluid content">
      <section className="container app-container fixed-width content features">
        <div className="room-efficiency">
          <div className="left">
            <h2>Communify Meet</h2>
            <p>
              Exchange-based calendaring, at-a-glance room status, and walk-up
              reservation deployed on Surface.
            </p>
          </div>
          <div className="right">
            <img src={increaseRoom} alt=" increase Room" />
          </div>
        </div>

        <div className="room-efficiency">
          <div className="left">
            <img src={easyReservation} alt="room efficency" />
          </div>
          <div className="right">
            <h2>Communify Greet</h2>
            <p>
              All-in-one temperature gating kiosk integrated with the Microsoft
              Power Platform return to the workplace solution.
            </p>
            <Link
              className="notice-link"
              onClick={(e) => scrollToElem(`#notice`)}
              to="#notice"
            >
              <p>See notice regarding Communify Greet</p>
            </Link>
          </div>
        </div>

        <div className="room-efficiency">
          <div className="left">
            <h2>Communify Seat</h2>
            <p>
              Installed centrally to capacity-based workspaces, Seat provides
              at-a-glance availability of your workspaces, broadcasts digital
              signage, and enables walk-up reservation and touch-free check-in.
            </p>
          </div>
          <div className="right">
            <img src={seatImage} style={{ width: '100%' }} alt="room efficency" />
          </div>
        </div>

        <div className="meet-now">
          <div className="left">
            <img src={meetNow} alt="Meet now" />
          </div>
          <div className="right">
            <h2>Communify App for Microsoft Teams</h2>
            <p>
              Room reservation and management from desktop and mobile clients.
            </p>
          </div>
        </div>

        <div className="digital-signange">
          <div className="left">
            <h2>Digital signage</h2>
            <p>
              Share messaging with centrally managed, targeted signage published
              to Communify endpoints.
            </p>
          </div>
          <div className="right">
            <img src={digitalSignage} alt="Digital sign age" />
          </div>
        </div>

        <div className="deployment-div">
          <div className="left">
            <h2>Deployment</h2>
            <p>
              Simple out-of-the-box device configuration makes it easy for IT to
              sync, link, and deploy.
            </p>
          </div>
          <div className="right">
            <h2>Device monitoring</h2>
            <p>
              Real-time proactive systems monitoring takes the guesswork out of
              management.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
