import React from "react";

const WhyMeet = () => {
  return (
    <section id="why-communify" className="why-meet">
      <div className="container fixed-width">
        <div>
          <h2>Why Communify?</h2>
        </div>
        <div className="content-div justify-content-between">
          <div className="border-green">
            <h4>IT</h4>
            <p>
              Integrations with M365 and Power Platform, and exclusive
              deployment on Surface makes Communify products friction-free
              additions to an organization's Microsoft ecosystem.
            </p>
          </div>
          <div className="border-pink">
            <h4>Facilities</h4>
            <p>
              Centralized management, smart integrations, device monitoring, and
              simple administration make it easy to incorporate Communify into
              existing management processes.
            </p>
          </div>
          <div className="border-indigo">
            <h4>Return to Work</h4>
            <p>
              Communify is helping organizations reimagine their workplace and
              the role of the office by empowering workforce safety and
              productivity.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyMeet;
