import React from "react";

const Notice = () => {
  return (
    <section id="notice" className="speak notice-wrapper">
      <div className="container fixed-width">
        <div>
          <h2>Communify Greet is not FDA-cleared or approved.</h2>
        </div>
        <div>
          <p>
            This device should not be solely or primarily relied upon to
            diagnose or exclude a diagnosis of COVID-19, or any other disease.
            Elevated body temperature in the context of use should be confirmed
            with secondary evaluation methods (e.g., a non-contact infrared
            thermometer or clinical grade contact thermometer). Public health
            officials, through their experience with the device in the
            particular environment of use, should determine the significance of
            any fever or elevated temperature based on the skin
            telethermographic temperature measurement. The system and technology
            should be used to measure only one subject’s temperature at a time
            and visible thermal patters are only intended for locating the
            points from which to extract the thermal measurement.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Notice;
