import React from 'react';
import Header from '../../Includes/Header';
import Footer from '../../Includes/Footer';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

const Privacy = () => {


      useEffect(() => {
            window.scrollTo(0,0)
      }, [])
      return (
            <div className='main-page p-0 terms-wrapper'>
                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>Privacy and Policy | Communify</title>
                        <meta name="description" content='Reduce workplace friction to get business back to work' />
                  </Helmet>
                  <section>
                        <Header />
                        <div className='container'>
                              <div className="terms-heading d-flex flex-column align-items-center">
                                    <h1 className='terms-title'>Application Privacy Policy</h1>
                                    <p className='last-modified'>Last Modified: May 11th, 2020</p>
                                    <section className="introduction">
                                          <h2>Introduction</h2>
                                          <div className='introduction-content'>
                                                <p>
                                                      Teknikos, Inc. (<span className='bold'>“Teknikos”</span> or <span className='bold'>“we”</span>) respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes:
                                                </p>
                                                <ul>
                                                      <li>
                                                            The types of information we may collect or that you may provide when you
                                                            download, install, register with, access, or use the Communify: Meet software
                                                            application or administration portal (collectively, the <span className='bold'>“App”</span>).
                                                      </li>
                                                      <li>
                                                            Our practices for collecting, using, maintaining, protecting, and disclosing that
                                                            information. </li>
                                                </ul>
                                          </div>
                                          <div className='introduction-content'>
                                                <p>
                                                      This policy DOES NOT apply to information that:
                                                </p>
                                                <ul>
                                                      <li>
                                                            We collect offline or on any other Teknikos apps or websites, including
                                                            websites you may access through this App.


                                                      </li>
                                                      <li>
                                                            You provide to or is collected by any third party (see Third-Party Information
                                                            Collection).
                                                            </li>
                                                </ul>
                                          </div>
                                          <div className="introcution-bottom">
                                                <p>
                                                      Our websites and apps, and these other third parties may have their own privacy
                                                      policies, which we encourage you to read before providing information on or
                                                      through them.
                                                </p>
                                                <p>
                                                      Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.
                                                </p>

                                          </div>
                                    </section>
                                    <section className='under-age'>
                                          <h2>Children Under the Age of 13 </h2>
                                          <p>
                                                The App is not intended for children under 13 years of age, and we do not knowingly
                                                collect personal information from children under 13. If we learn we have collected or
                                                received personal information from a child under 13 without verification of parental
                                                consent, we will delete that information. If you believe we might have any
                                                nformation from or about a child under 13, please contact us at <a href="mailto:communify-privacy@teknikos.com">communify-privacy@teknikos.com.</a>


                                          </p>
                                          <p>
                                                California residents under 16 years of age may have additional rights regarding the
                                                collection and sale of their personal information. Please see Your California Privacy
                                                Rights for more information.
                                          </p>
                                    </section>
                                    <section className='information'>
                                          <h2>Information We Collect and How We Collect It </h2>
                                          <p>We collect information from and about users of our App: </p>
                                          <ul>
                                                <li>
                                                      Directly from you when you provide it to us.
                                                </li>
                                                <li>
                                                      Directly from other App users when they provide it to us.
                                                </li>
                                                <li>
                                                      Automatically when you use the App.
                                                </li>
                                          </ul>
                                          <p className='info-alert'>Information You Provide to Us </p>
                                          <p>When you download, register with, or use this App, we may ask you provide
                                          information:  </p>
                                          <ul>
                                                <li>
                                                      By which you may be personally identified, such as name, postal address,
                                                      email address, telephone number, or any other identifier by which you may be
                                                      contacted online or offline (<span className='bold'>"personal information"</span>).
                                                </li>
                                                <li>
                                                      That is about you but individually does not identify you, such as information
                                                      about meetings on your calendar.
                                                </li>
                                          </ul>
                                          <p>This information includes:  </p>
                                          <ul>
                                                <li>
                                                      Information that you provide by filling in forms in the App or content you  upload through the App. This includes information provided at the time of
                                                      registering to use the App and activating your subscription to our service. We
                                                      may also ask you for information when you report a problem with the App.
                                                </li>
                                                <li>
                                                      Records and copies of your correspondence (including email addresses and
                                                      phone numbers), if you contact us.
                                                </li>
                                                <li>
                                                      Details of actions you carry out through the App, including, but not limited to
                                                      calendar appointments, meeting attendee lists and other meeting information.
                                                </li>
                                                <li>
                                                      You may also provide information for publication or display ( <span className='bold'>“posted”</span>) on areas
                                                      of the app available to other users (collectively, <span className="bold">“User Contributions”</span>). Your
                                                      User Contributions are Posted and transmitted to others at your own risk.
                                                      Additionally, we cannot control the actions of third parties with whom you may
                                                      choose to share your User Contributions. Therefore, we cannot and do not
                                                      guarantee that your User Contributions will not be viewed by unauthorized
                                                      persons.
                                                </li>
                                          </ul>
                                          <p className="info-alert">
                                                Automatic Information Collection and Tracking
                                          </p>
                                          <p>
                                                When you download, access, and use the App, it may use technology to automatically collect:
                                          </p>
                                          <ul>
                                                <li>
                                                      <span className="bold"> Usage Details.</span> When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, logs, and other communication data and the resources that you access and use on or through the App.
                                                </li>
                                                <li>
                                                      <span className="bold"> Device Information.</span> We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, and mobile network information.
                                                </li>
                                                <li>
                                                      <span className="bold">Stored Information and Files.</span> The App also may access metadata and other information associated with other files stored on your device. This may include, for example, calendar entries, attendee lists, personal contacts, and address book information.
                                                </li>
                                                <li>
                                                      <span className="bold"> Location Information.</span> This App does not collect real-time information about the location of your device. Address information you enter into the App may be sent to a third party for autocompletion and correction.
                                                </li>
                                          </ul>
                                          <p>
                                                If you do not want us to collect this information do not use the Administration Portal function of the App.
                                                </p>
                                          <p>
                                                We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking).
                                                </p>
                                          <p className="info-alert">Information Collection and Tracking Technologies </p>
                                          <p>The technologies we use for automatic information collection may include:</p>
                                          <ul>
                                                <li>
                                                      <span className="bold">Cookies (or mobile cookies).</span> A cookie is a small file placed on your mobile device. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your mobile device. However, if you select this setting you may be unable to access certain parts of our App.
                                                      </li>
                                                <li>
                                                      <span className="bold">Web Beacons.</span> Pages of the App may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Teknikos, for example, to count users who have visited those pages and for other related app statistics (for example, recording the usage of certain app functionality and verifying system and server integrity).
                                                      </li>
                                                <li>
                                                      <span className="bold">Client application hardware IDs.</span> This is not reverse trackable to the device but helps the App maintain pairing with the server.
                                                      </li>
                                          </ul>
                                          <p className="info-alert">
                                                Third-Party Information Collection
                                                </p>
                                          <p>When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include: </p>
                                          <ul>
                                                <li>Your mobile device manufacturer.</li>
                                                <li>Your mobile service provider</li>
                                                <li>Your operating system manufacturer.</li>
                                          </ul>
                                          <p>
                                                These third parties may use tracking technologies to collect information about you when you use this app. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
                                          </p>
                                          <p>We do not control these third parties' tracking technologies or how they may be used. </p>
                                          <p className="underlined-info">How We Use Your Information</p>
                                          <p>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
                                          <ul>
                                                <li>Provide you with the App and its contents, and any other information, products or services that you request from us.</li>
                                                <li>Fulfill any other purpose for which you provide it.</li>
                                                <li>Give you notices about your account and subscription, including expiration and renewal notices.</li>
                                                <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
                                          </ul>
                                          <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
                                          <ul>
                                                <li>Estimate our audience size and usage patterns.</li>
                                                <li>Store information about your preferences, allowing us to customize our App according to your individual interests.</li>
                                                <li>Recognize you when you use the App.</li>
                                          </ul>
                                          <p>For more information, see Your Choices About Our Collection, Use, and Disclosure of Your Information.</p>
                                          <p className="underlined-info">Disclosure of Your Information</p>
                                          <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction. </p>
                                          <p>In addition, we may disclose personal information that we collect or you provide:</p>
                                          <ul>
                                                <li>To our subsidiaries and affiliates.</li>
                                                <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                                                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Teknikos' assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Teknikos about our App users is among the assets transferred.</li>
                                                <li>To fulfill the purpose for which you provide it. For example, if you provide information with respect to a specific meeting in a certain location, the App will display such information to other users.</li>
                                                <li>For any other purpose disclosed by us when you provide the information.</li>
                                                <li>With your consent.</li>
                                                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                                                <li>To enforce our rights arising from any contracts entered into between you and us, including the App End User License Agreement and SaaS Subscription Terms and Conditions.</li>
                                                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Teknikos, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.</li>
                                          </ul>
                                          <p className="underlined-info">Your Choices About Our Collection, Use, and Disclosure of Your Information</p>
                                          <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information. </p>
                                          <ul>
                                                <li> <span className='bold'> Tracking Technologies.</span> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
                                                <li> <span className='bold'> Promotion by the Company.</span> If you want us to use your email address or other contact information to promote our other products or services, you can opt-in by adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <a href="mailto:communify-privacy@teknikos.com">communify-privacy@teknikos.com</a>. You can also always opt-out by logging into the App and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <a href="mailto:communify-privacy@teknikos.com"> communify-privacy@teknikos.com.</a></li>
                                                <li> <span className='bold'> Disclosure of Your Information for Third-Party Advertising and Marketing.</span> We do not share your personal information with unaffiliated or non-agent third parties for advertising and marketing purposes.</li>
                                          </ul>
                                          <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (" <span className="bold">NAI</span>") on the NAI's website.</p>
                                          <p>California, European Union (“EU”), European Economic Area (“EAA”) member state and Switzerland residents may have additional personal information rights and choices. Please see Your California Privacy Rights or Data Protection Rights Under General Data Protection Regulation (GDPR) for more information, as applicable.</p>
                                          <p className="underlined-info">Accessing and Correcting Your Personal Information</p>
                                          <p>You can review and change your personal information by logging into the App and visiting your account profile page.</p>
                                          <p>You may also send us an email at  <a href="mailto:communify-privacy@teknikos.com"> communify-privacy@teknikos.com</a> to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
                                          <p>Please note that if you delete your User Contributions from the App, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other App users. </p>
                                          <p>California, EU, EAA member state and Switzerland residents may have additional personal information rights and choices. Please see Your California Privacy Rights or Data Protection Rights Under General Data Protection Regulation (GDPR) for more information, as applicable.</p>
                                          
                                          <p className="underlined-info">Your California Privacy Rights</p>
                                          <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. These rights include the right to request that we disclose certain information to you about our collection and use of your personal information over the course of the past 12 months (a “Verifiable Consumer Request”). </p>
                                          <p>Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a Verifiable Consumer Request related to your personal information. You may only make a Verifiable Consumer Request twice within a 12-month period.  To make a Verifiable Consumer Request, you should email us at  <a href="mailto:communify-privacy@teknikos.com"> communify-privacy@teknikos.com</a>.</p>
                                          <p>The Verifiable Consumer Request must provide sufficient information to allow us to reasonably verify you are the person about whom we collected personal information or an authorized representative.  In addition, you must describe your request with sufficient detail to allow us to properly understand, evaluate, and respond to it. Once we receive and confirm your Verifiable Consumer Request, we will disclose to you: (1) the categories and the sources of personal information we collected about you; (2) our business purpose for collecting your personal information; (3) the categories of third parties with whom we share that personal information; (4) the specific pieces of personal information we collected about you; and/or (5) whether we sold or disclosed your personal information for a business purpose (and if so, the categories of personal information sold or disclosed). To learn more about your California privacy rights, visit https://oag.ca.gov/privacy/ccpa.  </p>
                                          <p>California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to  <a href="mailto:communify-privacy@teknikos.com"> communify-privacy@teknikos.com</a> or write us at: Teknikos, Inc., ATTN: Communify Privacy CA, 1500 Industry Road, STE T, Hatfield, PA 19440. Note, however, that Teknikos does not share your personally identifiable information with third parties for the third parties’ direct marketing purposes.</p>
                                          <p className='underlined'>Data Protection Rights Under General Data Protection Regulation (GDPR)</p>
                                          <p>Teknikos is headquartered in the USA.  If you are a resident of the EU, an EAA member state, or Switzerland, you have certain data protection rights under the GDPR, including:</p>
                                          <ul>
                                                <li> <span className='bold'> The right to access, update or to delete the information we have on you.</span>  You have the right to request to access, to update or request deletion of your personal information in Teknikos’ possession.  If you would like exercise this right, please contact Teknikos to assist you.</li>
                                                <li> <span className='bold'> The right of rectification.</span>  If you believe that your personal information that we possess is, or has become, incorrect or is incomplete, you have the right request to rectify any of the Personal Information we may have about you.</li>
                                                <li> <span className='bold'> The right to object.</span>  You have the right to object to Teknikos’ processing of your Personal Information.</li>
                                                <li> <span className='bold'> The right of restriction.</span>  You have the right to request that we restrict the processing of your personal information.</li>
                                                <li> <span className='bold'> The right to data portability.</span>  You have the right to be provided with a copy of the information Teknikos has on you in a structured, machine-readable and commonly used format.</li>
                                                <li> <span className='bold'> The right to withdraw consent.</span>  You also have the right to withdraw your consent at any time, where Teknikos relied on your consent to process your Personal Information, without affecting the lawfulness of Teknikos’ processing based on consent before its withdrawal.</li>
                                          </ul>
                                          <p>Please note that we may ask you to verify your identity before responding to any of the above requests and that the above individual rights are not absolute.  Teknikos may be entitled to refuse requests where certain exceptions apply.  If you have given your consent and you wish to withdraw it, please contact us using the contact details provided at the end of this policy.  Please note that where processing of your personal information relies on your consent and where you then withdraw that consent, we may not be able to provide all or some aspects of Teknikos’ equipment, products or services to you, or it may affect the provision of such equipment, products or services.</p>
                                          <p>If you believe that Teknikos has not complied with applicable foreign laws regulating such information, you should first contact Teknikos using the contact details provided at the end of this policy.  You also have the right to file a complaint with the appropriate supervisory authority in the EEA about Teknikos’ collection and use of your personal information.  For more information, please contact your local data protection authority in the EAA.  You may also contact Teknikos to be directed to the relevant authorities.</p>
                                          <p className="underlined-info">Data Security</p>
                                          <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. Private information you provide is encrypted and stored in cloud-based servers, protected by an additional layer of encryption and behind secure firewalls. No identifiable information is stored unprotected.</p>
                                          <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. </p>
                                          <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
                                          <p className="underlined-info">Changes to Our Privacy Policy</p>
                                          <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and notify registered users of the App’s Administration Portal via email after we make the change.</p>
                                          <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for periodically visiting this privacy policy to check for any changes. This privacy policy can always be found at https://www.communifyapp.com/privacy.html</p>
                                          <p className="underlined-info">Contact Information</p>
                                          <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: </p>
                                          <p> <a href="mailto:communify-privacy@teknikos.com"> communify-privacy@teknikos.com</a></p>
                                    </section>

                              </div>

                        </div>
                  </section>
                  <Footer />
            </div>
      );
}

export default Privacy;
