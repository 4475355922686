import React from 'react';

import logo from '../../Assets/Images/logo.svg';
import closeMobileMenu from '../../Assets/Images/btn-nav-mobile-expanded.svg';
import customerIcon from '../../Assets/Images/icon-customer-login.svg';
import { toggleMobileMenu } from '../../Utils';
import MobileMenu from '../../UI/Navigation/MobileMenu';

const Overlay = () => {
      return (
            <div id="mobile-nav" className="overlay">
                  <div className='app-container d-flex justify-content-between'>
                        <img src={logo} alt="Website Logo" />
                        <img onClick={e => toggleMobileMenu('close')} className='img-fluid' src={closeMobileMenu} alt="Close mobile menu" />
                  </div>
                  <MobileMenu />
                  <div className="customer-login d-flex">
                        <div className="customer-icon mr-2">
                              <img src={customerIcon} alt="Customer Icon" />
                        </div>
                        <div className="customer-text">
                              <a target='_blank' rel="noopener noreferrer" href='https://admin.communifyapp.com/login'>Customer Login</a>
                        </div>
                  </div>
            </div>
      );
}

export default Overlay;
