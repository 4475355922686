import React from "react";
import Header from "../../Includes/Header";
import Hero from "./Hero";
import Features from "./Features";
import WhyMeet from "./WhyMeet";
import Connect from "./Connect";
import Footer from "../../Includes/Footer";
import Notice from "./Notice";

const Main = () => {
  return (
    <div className="main-page p-0">
      <Header />
      <Hero />
      <Features />
      <WhyMeet />
      <Connect />
      <Notice />
      <Footer />
    </div>
  );
};

export default Main;
