const SET_USER = "SET_USER";

const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

const SET_DIMENSIONS = "SET_DIMENSIONS";

export {
      SET_USER,
      SET_SUCCESS_MESSAGE,
      SET_ERROR_MESSAGE,
      SET_DIMENSIONS
};