import * as actionTypes from '../ActionTypes';

const setUser = user => ( { type: actionTypes.SET_USER, user } );

const setSuccessMessage = message => ( { type: actionTypes.SET_SUCCESS_MESSAGE, message } );
const setErrorMessage = message => ( { type: actionTypes.SET_ERROR_MESSAGE, message } );

const setDimensions = dimensions => ({ type: actionTypes.SET_DIMENSIONS, dimensions })

export {
      setUser,
      setSuccessMessage,
      setErrorMessage,
      setDimensions
};