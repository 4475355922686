import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { menuItems } from '../../../Configs/Menu/MenuItems';
import scrollToElem from '../../Libs/smoothScrollPolyfill';

const Menu = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <ul className="nav custom-nav justify-content-center pt-3">
        {menuItems &&
          menuItems.map((elem, index) => (
            <li key={index} className="nav-item">
              <Link
                onClick={e => scrollToElem(`${elem.hash}`)}
                className="nav-link"
                to={{
                  pathname: '/',
                  hash: `${elem.hash}`,
                  state: { from: history.location, elemId: `${elem.hash}`, overlay: false },
                }}
              >
                {elem.name}
              </Link>
            </li>
          ))}
        <li className="nav-item">
          <a href="https://releasenotes.communifyapp.com/" className="nav-link" target="_blank">
            Release Notes
          </a>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Menu;
