import React, { useEffect } from 'react';
import TopBar from './TopBar';
import Navigation from './Navigation';
import Overlay from './Overlay';

import { scrollingTopDetector } from '../../Utils';

const Header = () => {
      useEffect(() => {
            window.addEventListener('scroll', e => scrollingTopDetector());
      })
      return (
            <div id='header-wrapper' className='animted-header-expand'>
                  <Overlay />
                  <div className='header container app-container p-0'>
                        <TopBar />
                        <Navigation />
                  </div>
            </div>
      );
}

export default Header;
