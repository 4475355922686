import React, { useEffect } from "react";
import { useLocation } from "react-router";
import scrollToElem from "../../../Libs/smoothScrollPolyfill";

const NavBarSupportNew = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scrollToElem(location.hash);
      const el = document.querySelector(`[href="${location.hash}"]`) || null;
      if (el) {
        el.classList.add("make-active-link-black");

        return () => {
          el.classList.remove("make-active-link-black");
        };
      }
    }
  }, [location]);

  return (
    <div className="nav-bar">
      <h3>In the support guide</h3>
      <span>
        <a href="#toWelcomeToComunity">Welcome to Communify</a>
      </span>
      <span>
        <a href="#toSettings">Settings</a>
      </span>
      <span>
        <a href="#toOffices">Offices</a>
      </span>
      <span>
        <a href="#toDigitalSignage">Digital Signage</a>
      </span>
      <span>
        <a href="#toSubscriptions">Subscriptions</a>
      </span>
      <span>
        <a href="#toDevices">Devices</a>
      </span>
      <span>
        <a href="#toUsers">Users</a>
      </span>
      <span>
        <a href="#toCommunifyMeet">Communify Meet</a>
      </span>
      <span>
        <a href="#toCommunifyGreet">Communify Greet</a>
      </span>
      <span>
        <a href="#toCommunifySeat">Communify Seat</a>
      </span>
      <span>
        <a href="#toCommunifyApp">Communify App</a>
      </span>
    </div>
  );
};

export default NavBarSupportNew;
