import React from "react";
import { Link } from "react-router-dom";

const EULAcontent = () => (
  <>
    <div>
      <h1>End User License Agreement</h1>
      <span>Last Modified: October 20th, 2020</span>
    </div>

    <div className="eula-content-wrapper">
      <div className="eula-content-inner">
        <p>
          This End User License Agreement (“<strong>Agreement</strong>”) is a
          binding agreement between you and Teknikos, Inc. (“
          <strong>Teknikos</strong>”). This Agreement governs your use of one or
          more software applications included in the “Communify” suite of
          software applications, a full listing of which is available at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.communifyapp.com"
          >
            www.communifyapp.com
          </a>
          , (including all related documentation, collectively the{" "}
          <strong>“Application”</strong>). The Application is licensed, not
          sold, to you.
          <br />
          <br />
          BY DOWNLOADING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ
          AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE
          TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND
          AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO
          THESE TERMS, DO NOT DOWNLOAD OR USE THE APPLICATION.
        </p>
        <ol>
          <li>
            <u>License Grant for Supported Devices</u>. Subject to the terms of
            this Agreement, Teknikos grants you a limited, non-exclusive, and
            nontransferable license to download, install, and use the
            Application for your commercial use on a supported Microsoft
            Surface® device owned or otherwise controlled by you (“
            <strong>Mobile Device</strong>”) strictly in accordance with the
            Application's documentation. The Application is solely supported on
            Microsoft Surface® Go and Microsoft Surface® Pro devices running
            Windows 10, version 1903 or later.
          </li>
          <li>
            <u>License Restrictions</u>. You shall not:
            <ol type="a">
              <li>
                copy the Application, except as expressly permitted by this
                license;
              </li>
              <li>
                modify, translate, adapt, or otherwise create derivative works
                or improvements, whether or not patentable, of the Application;
              </li>
              <li>
                reverse engineer, disassemble, decompile, decode, or otherwise
                attempt to derive or gain access to the source code of the
                Application or any part thereof;
              </li>
              <li>
                remove, delete, alter, or obscure any trademarks or any
                copyright, trademark, patent, or other intellectual property or
                proprietary rights notices from the Application, including any
                copy thereof;
              </li>
              <li>
                rent, lease, lend, sell, sublicense, assign, distribute,
                publish, transfer, or otherwise make available the Application
                or any features or functionality of the Application, to any
                third party, other than your employees, for any reason, except
                as expressly permitted in writing by Teknikos; or
              </li>
              <li>
                remove, disable, circumvent, or otherwise create or implement
                any workaround to any copy protection, rights management, or
                security features in or protecting the Application.
              </li>
            </ol>
          </li>
          <li>
            <u>Reservation of Rights</u>. You acknowledge and agree that the
            Application is provided under license, and not sold, to you. You do
            not acquire any ownership interest in the Application under this
            Agreement, or any other rights thereto other than to use the
            Application in accordance with the license granted, and subject to
            all terms, conditions, and restrictions, under this Agreement.
            Teknikos and its licensors and service providers reserve and shall
            retain their entire right, title, and interest in and to the
            Application, including all copyrights, trademarks, and other
            intellectual property rights therein or relating thereto, except as
            expressly granted to you in this Agreement.
          </li>
          <li>
            <u>Collection and Use of Your Information</u>. You acknowledge that
            when you download, install, or use the Application, Teknikos may use
            automatic means (including, for example, cookies and web beacons) to
            collect information about your Mobile Device and about your use of
            the Application. You also may be required to provide certain
            information about yourself as a condition to downloading,
            installing, or using the Application or certain of its features or
            functionality. All information we collect through or in connection
            with this Application is subject to our{" "}
            <Link to="/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
            . By downloading, installing, using, and providing information to or
            through this Application, you consent to all actions taken by us
            with respect to your information in compliance with the Privacy
            Policy.
          </li>
          <li>
            <u>Updates</u>. Teknikos may from time to time in its sole
            discretion develop and provide Application updates, which may
            include upgrades, bug fixes, patches, other error corrections,
            and/or new features (collectively, including related documentation,{" "}
            <strong>“Updates”</strong>). Updates may also modify or delete in
            their entirety certain features and functionality. You agree that
            Teknikos has no obligation to provide any Updates or to continue to
            provide or enable any particular features or functionality. Based on
            your Mobile Device settings, when your Mobile Device is connected to
            the internet either:
            <ol type="a">
              <li>
                the Application will automatically download and install all
                available Updates; or
              </li>
              <li>
                you may receive notice of or be prompted to download and install
                available Updates.
              </li>
            </ol>
            You shall promptly download and install all Updates and acknowledge
            and agree that the Application or portions thereof may not properly
            operate should you fail to do so. You further agree that all Updates
            will be deemed part of the Application and be subject to all terms
            and conditions of this Agreement.
          </li>
          <li>
            <u>Third-Party Materials</u>. The Application may display, include,
            or make available third-party content (including data, information,
            applications, and other products, services, and/or materials) or
            provide links to third-party websites or services, including through
            third-party advertising (“<strong>Third-Party Materials</strong>”).
            You acknowledge and agree that Teknikos is not responsible for
            Third-Party Materials, including their accuracy, completeness,
            timeliness, validity, copyright compliance, legality, decency,
            quality, or any other aspect thereof. Teknikos does not assume and
            will not have any liability or responsibility to you or any other
            person or entity for any Third-Party Materials. Third-Party
            Materials and links thereto are provided solely as a convenience to
            you, and you access and use them entirely at your own risk and
            subject to such third parties' terms and conditions.
          </li>
          <li>
            <u>Term and Termination</u>.
            <ol type="a">
              <li>
                The term of Agreement commences when you download the
                Application and will continue in effect until terminated by you
                or Teknikos as set forth in this Section 7.
              </li>
              <li>
                You may terminate this Agreement by deleting the Application and
                all copies thereof from your Mobile Device.
              </li>
              <li>
                Teknikos may terminate this Agreement at any time without notice
                if it ceases to support the Application, which Teknikos may do
                in its sole discretion. In addition, this Agreement will
                terminate immediately and automatically without any notice if
                you violate any of the terms and conditions of this Agreement.
              </li>
              <li>
                Upon termination:
                <ol type="i">
                  <li>
                    all rights granted to you under this Agreement will also
                    terminate; and
                  </li>
                  <li>
                    you must cease all use of the Application and delete all
                    copies of the Application from your Mobile Device and
                    account.
                  </li>
                </ol>
              </li>
              <li>
                Termination will not limit any of Teknikos' rights or remedies
                at law or in equity.
              </li>
            </ol>
          </li>
          <li>
            <u>Disclaimer of Warranties</u>. THE APPLICATION IS PROVIDED TO YOU
            "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY
            KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW,
            TEKNIKOS, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS
            AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY
            DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
            OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF
            COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE.
            WITHOUT LIMITATION TO THE FOREGOING, TEKNIKOS PROVIDES NO WARRANTY
            OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
            APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
            RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
            APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION,
            MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR
            THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
            <br />
            <br />
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
            IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
            RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
            LIMITATIONS MAY NOT APPLY TO YOU.
          </li>
          <li>
            <u>Limitation of Liability.</u> TO THE FULLEST EXTENT PROVIDED BY
            LAW, IN NO EVENT WILL TEKNIKOS, ITS AFFILIATES, OR THEIR LICENSORS,
            SERVICE
            <br />
            <br />
            PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR
            DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
            CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APP, ANY WEBSITES
            LINKED TO IT, ANY CONTENT ON THE APPLICATION OR SUCH WEBSITES,
            INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
            OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY,
            PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
            PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS
            OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
            <br />
            <br />
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </li>
          <li>
            <u>Indemnification</u>. You agree to indemnify, defend, and hold
            harmless Teknikos and its officers, directors, employees, agents,
            affiliates, successors, and assigns from and against any and all
            losses, damages, liabilities, deficiencies, claims, actions,
            judgments, settlements, interest, awards, penalties, fines, costs,
            or expenses of whatever kind, including reasonable attorneys' fees,
            arising from or relating to your use or misuse of the Application or
            your breach of this Agreement, including but not limited to the
            content you submit or make available through this Application.
          </li>
          <li>
            <u>Export Regulation</u>. The Application may be subject to US
            export control laws, including the Export Control Reform Act and its
            associated regulations. You shall not, directly or indirectly,
            export, re-export, or release the Application to, or make the
            Application accessible from, any jurisdiction or country to which
            export, re-export, or release is prohibited by law, rule, or
            regulation. You shall comply with all applicable federal laws,
            regulations, and rules, and complete all required undertakings
            (including obtaining any necessary export license or other
            governmental approval), prior to exporting, re-exporting, releasing,
            or otherwise making the Application available outside the US.
          </li>
          <li>
            <u>Severability</u>. If any provision of this Agreement is illegal
            or unenforceable under applicable law, the remainder of the
            provision will be amended to achieve as closely as possible the
            effect of the original term and all other provisions of this
            Agreement will continue in full force and effect.
          </li>
          <li>
            <u>Governing Law</u>. This Agreement is governed by and construed in
            accordance with the internal laws of the Commonwealth of
            Pennsylvania without giving effect to any choice or conflict of law
            provision or rule. Any legal suit, action, or proceeding arising out
            of or related to this Agreement or the Application shall be
            instituted exclusively in the federal courts of the United States or
            the courts of the Commonwealth of Pennsylvania in each case located
            in Philadelphia and Philadelphia County. You waive any and all
            objections to the exercise of jurisdiction over you by such courts
            and to venue in such courts.
          </li>
          <li>
            <u>Limitation of Time to File Claims</u>. ANY CAUSE OF ACTION OR
            CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR
            THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
            CAUSE
            OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS
            PERMANENTLY BARRED.
          </li>
          <li>
            <u>Entire Agreement</u>. This Agreement, the SaaS Subscription Terms
            and Conditions, our Privacy Policy, and all other policies and
            disclaimers incorporated by reference therein constitute the entire
            agreement between you and Teknikos with respect to the Application
            and supersede all prior or contemporaneous understandings and
            agreements, whether written or oral, with respect to the
            Application. To the extent any terms or provisions of the foregoing
            documents may be deemed inconsistent, the SaaS Subscription Terms
            and Conditions shall govern; provided, however, that if such
            inconsistency is deemed by Teknikos, in its sole discretion, to be
            related to privacy, the Privacy Policy shall govern.
          </li>
          <li>
            <u>Waiver</u>. No failure to exercise, and no delay in exercising,
            on the part of either party, any right or any power hereunder shall
            operate as a waiver thereof, nor shall any single or partial
            exercise of any right or power hereunder preclude further exercise of
            that or any other right hereunder. In the event of a conflict
            between this Agreement and any applicable purchase or other terms,
            the terms of this Agreement shall govern.
          </li>
        </ol>
      </div>
    </div>
  </>
);

export default EULAcontent;
